import React, { useContext } from 'react';

import AccountBoxIcon from '@material-ui/icons/AccountBox';

import { componentBorderColor } from '../theme/component.setup';
import { UserContext } from '../user/user-provider';

export function UserIcon() {
  const currentUser = useContext(UserContext);
  if (!currentUser.user.isAuthenticated) {
    return <AccountBoxIcon />;
  }
  if (currentUser.user.picture) {
    return <img src={currentUser.user.picture} alt="avatar" width={24} height={24} />;
  }
  return <AccountBoxIcon style={{ color: componentBorderColor }} />;
}
