import React, { useState } from 'react';

import { IdeTester } from '../components/assembly-code/ide-tests';
import { useMessages } from '../layout/snackbars';
import { LoadableFunction } from '../model/code/function';
import { DialogConfirmDeleteFunction } from './ConfirmDeleteFunction';


export type EditorFocus = 'functionName' | 'code';
export type OpenFunction = { functionId: string; focus: EditorFocus };


export type Action =
    | {
        type: 'add' | 'replace' | 'delete';
        add: LoadableFunction;
        idx: number;
    }
    | { type: 'init'; init?: LoadableFunction[] };


export type CodeEditorProps = {
    functions: LoadableFunction[];
    dispatchFunctions: React.Dispatch<Action>;
    openFunctionId?: string;
    focus?: EditorFocus;
};

export function CodeEditor({ openFunctionId, functions, dispatchFunctions, focus }: CodeEditorProps) {
    const messages = useMessages();
    const [confirmDeleteFunction, setConfirmDeleteFunction] = useState<LoadableFunction | undefined>(undefined);

    const openLoadableFunction = functions.find((f) => f.id === openFunctionId);

    const setAlertError = () => {
        messages.display({ severity: 'error', message: 'Error while connecting to server' });
    }

    const replaceFunction = async (newFct: LoadableFunction) => {
        const idx = functions.findIndex((f) => f.id === newFct.id);
        await dispatchFunctions({ type: 'replace', idx, add: newFct });
    };

    const deleteFunction = async () => {
        if (!confirmDeleteFunction) {
            return;
        }
        const idx = functions.findIndex((f) => f.id === confirmDeleteFunction.id);
        await dispatchFunctions({ type: 'delete', idx, add: confirmDeleteFunction });
        setConfirmDeleteFunction(undefined);
    };
    const handleConfirmDeleteFunction = async (fct: LoadableFunction) => {
        setConfirmDeleteFunction(fct);
    };

    return <div
        tabIndex={0}
    >
        <IdeTester
            focus={focus}
            loadableFunction={openLoadableFunction}
            setAlertError={setAlertError}
            replaceFunction={replaceFunction}
            deleteFunction={handleConfirmDeleteFunction}
        />

        <DialogConfirmDeleteFunction open={!!confirmDeleteFunction} onClose={() => setConfirmDeleteFunction(undefined)} onConfirm={deleteFunction} />

    </div>;
}
