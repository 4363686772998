import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';


export function DialogConfirmDeleteFunction({ open, onClose, onConfirm }: { open: boolean; onClose: () => void; onConfirm: () => void }) {
    const [t] = useTranslation();
    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t('function.delete.confirmTitle')}</DialogTitle>
        <DialogContent>
            {t('function.delete.confirm')}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                {t('function.delete.cancel')}
            </Button>
            <Button onClick={onConfirm}>
                {t('function.delete.ok')}
            </Button>
        </DialogActions>
    </Dialog>
}