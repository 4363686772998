import React from 'react';

import { Icon, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconDto, ThemeColor } from '../../model/challenges/challenge-repository';
import { circuitComponentTypeCatalog } from '../circuit/ui/component-view';

import * as FontAwesome from '@fortawesome/free-solid-svg-icons';
import { red, green } from '@material-ui/core/colors';
import { componentBorderColor } from '../theme/component.setup';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

const FontAwesomeIcons = FontAwesome as unknown as Record<string, IconDefinition>;

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.common.white,
  },
  low: {
    color: red[600],
  },
  high: {
    color: green[600],
  },
  tron: {
    color: componentBorderColor,
  },
}));

function Capsule({ children, color }: { children?: React.ReactNode; color?: ThemeColor }) {
  const classes = useStyles();
  return (
    <div className={classes[color || 'primary']} style={{ width: '36px', height: '36px', margin: 'auto' }}>
      {children}
    </div>
  );
}
export function IconChallenge({ icon, color }: { icon: IconDto; color?: ThemeColor }) {
  return (
    <Capsule color={color}>
      {icon.component ? (
        circuitComponentTypeCatalog[icon.component]?.mapImg
      ) : icon.material ? (
        <Icon style={{ fontSize: '36px' }}>{icon.material}</Icon>
      ) : icon.fontawesome ? (
        <FontAwesomeIcon icon={FontAwesomeIcons[`fa${icon.fontawesome}`]} style={{ width: '100%', height: '100%' }} />
      ) : undefined}
    </Capsule>
  );
}
