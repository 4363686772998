import React from 'react';

import { Grid, useTheme } from '@material-ui/core';

import { RegisterDirection, registerWidth } from '../theme/register.setup';
import { RegisterState } from '../../model/microprocessor/register/register-state';
import { Register } from '../register/register';
import { Uint8 } from '../../model/integer/uint8';
import { apeArchitectures, Dialect } from '../../model/code/function';


export type MicroControllerLayoutProps = React.PropsWithChildren<{
  dialect: Dialect;
  outputValue: Uint8[];
  running: boolean;
  inputValue: Uint8[];
  ip: Uint8;
  acc: Uint8;
  registers: Uint8[];
}>;

export function MicroControllerLayout(props: MicroControllerLayoutProps) {
  const theme = useTheme();
  return (
    <div data-tour="challenge01-functions-codeeditor">
      <Grid container direction="column">
        <Grid item>
          <Grid container>
            <Grid item style={{ flexBasis: `${registerWidth(1)}`, flexGrow: 0 }}>
              <Grid container direction="column-reverse" justify="flex-start" style={{ height: '100%' }}>
                <Grid item></Grid>
                {apeArchitectures[props.dialect].input.map((input, idx) => <Grid item key={idx}>
                  <div style={{ display: 'inline-block' }}>
                    <Register
                      name={input}
                      direction={RegisterDirection.Input}
                      value={props.inputValue[idx] || new Uint8(0)}
                      state={RegisterState.Inactive}
                    ></Register>
                  </div>
                </Grid>)}
              </Grid>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              {props.children}
            </Grid>
            <Grid item style={{ flexBasis: `${registerWidth(1)}`, flexGrow: 0 }}>
              <Grid container direction="column-reverse" justify="flex-start" style={{ height: '100%' }}>
                <Grid item></Grid>
                {apeArchitectures[props.dialect].output.map((input, idx) => <Grid item key={idx}>
                  <div style={{ display: 'inline-block' }}>
                    <Register
                      name={input}
                      direction={RegisterDirection.Output}
                      value={props.outputValue[idx] || new Uint8(0)}
                      state={RegisterState.Inactive}
                    ></Register>
                  </div>
                </Grid>)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="space-evenly">
            <Grid item>
              <Register direction={RegisterDirection.Memory} state={RegisterState.Active} value={props.ip} name="IP"></Register>
            </Grid>
            <Grid item>
              <Register direction={RegisterDirection.Memory} state={RegisterState.Active} value={props.acc} name="Acc"></Register>
            </Grid>
          </Grid>
        </Grid>
        {apeArchitectures[props.dialect].registers.length ? <Grid item>
          <Grid container justify="center" style={{ marginTop: theme.spacing(0.3) }} spacing={1}>
            {apeArchitectures[props.dialect].registers.map((register, idx) => <Grid key={idx} item>
              <Register direction={RegisterDirection.Memory} state={RegisterState.Active} value={props.registers[idx]} name={register}></Register>
            </Grid>)}
          </Grid>
        </Grid> : undefined}
      </Grid>
    </div>
  );
}
