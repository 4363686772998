import React from 'react';

import { CatalogItem, circuitComponentTypeCatalog2, circuitComponentTypeCatalog } from './ui/component-view';
import { CircuitComponentType, CircuitComponent } from '../../model/components/components';
import { makeStyles } from '@material-ui/core';
import { componentBorderColorOpacity80 } from '../theme/component.setup';
import { red } from '@material-ui/core/colors';

export const rowSize = { unit: 'vw', size: 4 };
export const colSize = { unit: 'vw', size: 4 };

const useStyles = makeStyles({
  root: {
    width: (item: CatalogItem) => `calc(${rowSize.size * item.width}${rowSize.unit})`,
    height: (item: CatalogItem) => `calc(${colSize.size * item.height}${colSize.unit})`,
    zIndex: 100,
    fill: 'white',
    stroke: 'white',
    cursor: (item: CatalogItem) => (item.actions?.length ? 'pointer' : 'arrow'),
    '&:hover': {
      backgroundColor: (item: CatalogItem) => (item.actions?.length ? componentBorderColorOpacity80 : ''),
    },
  },
  empty: {
    color: red[500],
    opacity: 0.8,
  },
});

const nullItem: CatalogItem = { img: undefined, width: 0, height: 0 };

export function CircuitComponentDisplay({
  type,
  comp,
  onClick,
  loadedFunction,
}: {
  type: CircuitComponentType;
  comp?: CircuitComponent;
  onClick?: (el: Element) => void;
  loadedFunction: string;
}) {
  const item = comp ? circuitComponentTypeCatalog2(comp) : circuitComponentTypeCatalog[type];
  const classes = useStyles(item || nullItem);
  if (!item) return <></>;

  const classNames = [classes.root];
  if (item.canBeEmpty && loadedFunction === '-1') {
    classNames.push(classes.empty);
  }

  return (
    <div
      className={classNames.join(' ')}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick && onClick(e.currentTarget);
      }}
    >
      {item.img}
    </div>
  );
}
