import shortid from 'shortid';

import { CircuitComponentType } from '../components/components';

shortid.seed(new Date().getTime());

export type ComponentId = string;
export type FunctionId = string;
export type TestCaseId = string;

export function generateComponentId(componentType: CircuitComponentType): ComponentId | undefined {
  if (componentType === 'empty' || componentType === 'disabled') {
    return undefined;
  }
  return shortid.generate();
}

export function generateFunctionId(): FunctionId {
  return shortid.generate();
}

export function generateTestCaseId(): TestCaseId {
  return shortid.generate();
}
