import React, { useState } from 'react';
import { makeStyles, Typography, Popper, useTheme } from '@material-ui/core';
import { orange, red } from '@material-ui/core/colors';
import AceEditor, { IMarker, IAnnotation } from 'react-ace';

import { CompilableAssemblyCode } from '../../model/code/compilable-assembly-code/compilable-assembly-code';
import { Uint8 } from '../../model/integer/uint8';
import { darkColor, fontFamily, componentBorderColor } from '../theme/component.setup';
import { EditorFocus } from '../../library/code-editor';

const editorTheme = 'solarized_light';
const mode = 'assembly_x86';

import(`ace-builds/src-min-noconflict/theme-${editorTheme}`);
import(`ace-builds/src-min-noconflict/mode-${mode}`);

export type AssemblyCodeEditorProps = {
  code: CompilableAssemblyCode;
  onChange: (code: CompilableAssemblyCode) => void;
  disabled?: boolean;
  highlight?: Uint8;
  errors?: number[];
  highlightJmp0: boolean;
  editorFocus?: EditorFocus;
  runCurrentTest?: () => void;
};

const useStyles = makeStyles({
  ipLine: {
    backgroundColor: orange[400],
    opacity: 0.6,
    position: 'absolute',
    width: '100%',
  },
  editor: {
    '& .ace_tooltip': {
      display: 'none!important',
    },
  },
});

export function AssemblyCodeEditor(props: AssemblyCodeEditorProps) {
  const classes = useStyles();
  const theme = useTheme();
  const [tooltip, setTooltip] = useState<HTMLElement | undefined>();

  const handleChange = (value: string) => {
    const newCode = CompilableAssemblyCode.parse('\n', value);
    props.onChange(newCode);
  };

  const mouseTooltipHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;
    if (target && target.className && target.className.indexOf && target.className.indexOf('ace_error') >= 0) {
      setTooltip(target);
    }
  };

  const annotations = (): IAnnotation[] => {
    return (
      props.errors?.map((error) => ({
        row: error,
        column: 0,
        text: '',
        type: 'error',
      })) || []
    );
  };

  const markers = (): IMarker[] => {
    if (!props.highlight) {
      return [];
    }
    return [
      {
        startRow: props.highlight.toNumber(),
        endRow: props.highlight.toNumber(),
        startCol: 0,
        endCol: 2500,
        className: classes.ipLine,
        type: 'line',
        inFront: true,
      },
    ];
  };

  return (
    <div
      onMouseOut={() => {
        setTooltip(undefined);
      }}
      onMouseOver={mouseTooltipHandler}
      onKeyPressCapture={(e) => {
        if (e.key === 'Enter' && e.ctrlKey) {
          props.runCurrentTest && props.runCurrentTest();
        }
      }}
      style={{ height: '180px' }}
    >
      <AceEditor
        mode={mode}
        theme={editorTheme}
        setOptions={{ firstLineNumber: 0, tooltipFollowsMouse: false }}
        name="editor-yolo"
        className={classes.editor}
        value={props.code.join('\n')}
        onChange={handleChange}
        readOnly={props.disabled}
        markers={markers()}
        annotations={annotations()}
        width="100%"
        maxLines={12}
        minLines={12}
        focus={props.editorFocus === 'code'}
        style={{ maxHeight: '180px' }}
      />
      <Popper
        open={!!tooltip}
        anchorEl={tooltip}
        placement="left"
        style={{
          zIndex: theme.zIndex.drawer + 4,
          backgroundColor: darkColor,
          color: red[400],
          borderRadius: '4px',
          border: `1px solid ${componentBorderColor}`,
        }}
      >
        <Typography variant="body1" style={{ fontFamily: fontFamily, fontSize: '0.8em', padding: theme.spacing(0.8) }}>
          Compilation error
        </Typography>
      </Popper>
    </div>
  );
}
