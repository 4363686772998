import React, { ChangeEvent, FocusEvent } from 'react';

import { TextField, makeStyles, Grid, Typography, Icon, IconButton, useTheme, Divider } from '@material-ui/core';
import PlayIcon from '@material-ui/icons/PlayArrow';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import StopIcon from '@material-ui/icons/Stop';

import { Uint8 } from '../../model/integer/uint8';
import { componentBorderColor, fontFamily } from '../theme/component.setup';
import { useTranslation } from 'react-i18next';
import { TestCaseOneInputOneOutput } from '../../model/test/test-case';
import { ApeArchitecture } from '../../model/code/function';

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

const selectText = (e: FocusEvent<HTMLInputElement>) => e.currentTarget.select();

const handleChange = (callback?: (u: Uint8) => void) => {
  return (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.currentTarget.value);
    callback && callback(new Uint8(newValue));
  };
};

function switchCells(arr: Uint8[][], idx1: number, idx2: number): Uint8[][] {
  const newInValues = arr.concat([]);
  const previous = newInValues[idx1];
  newInValues[idx1] = newInValues[idx2];
  newInValues[idx2] = previous;
  return newInValues;
}

export type TestConfigPanelProps = {
  apeArchitecture: ApeArchitecture;
  testCase: TestCaseOneInputOneOutput;
  setInValues?: (newInValue: Uint8[][]) => void;
  setAccValue?: (newAccValue: Uint8) => void;
  setExpected?: (b: Uint8[]) => void;
  actions: {
    running: boolean;
    startTest: (tc: TestCaseOneInputOneOutput) => void;
    startTestSlow: (tc: TestCaseOneInputOneOutput) => void;
    stopTest: () => void;
  };
};

export function TestConfigPanel(props: TestConfigPanelProps) {
  const theme = useTheme();
  const classes = useStyle();

  const testCase = props.testCase;


  return (
    <>
      <TestButtons
        running={props.actions.running}
        startTest={() => {
          props.actions.startTest(props.testCase);
        }}
        startTestSlow={() => {
          props.actions.startTestSlow(props.testCase);
        }}
        stop={props.actions.stopTest}
      />
      <Divider orientation="vertical" flexItem style={{ marginRight: theme.spacing(1) }} />
      <form className={classes.root}>
        <Grid container spacing={2}>
          <Given testCase={testCase} onAccChange={props.setAccValue} />

          <When apeArchitecture={props.apeArchitecture} testCase={testCase} setInValues={props.setInValues} />

          <Then apeArchitecture={props.apeArchitecture} expected={testCase.expected} setExpected={props.setExpected} />
        </Grid>
      </form>
    </>
  );
}

function ExpectedOutput({ label, value, setValue }: { label: string; value: Uint8; setValue?: (val: Uint8) => void }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TextField
          type="number"
          label={label}
          variant="outlined"
          value={value.toNumber()}
          onChange={(e) => {
            const newValue = new Uint8(Number(e.currentTarget.value));
            setValue && setValue(newValue);
          }}
          fullWidth
          size="small"
          onFocus={selectText}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField type="text" label="hexadecimal" variant="outlined" value={`0x${value.toHex()}`} disabled fullWidth size="small" />
      </Grid>
    </Grid>
  );
}

function TestButtons({
  startTest,
  running,
  startTestSlow,
  stop,
}: {
  startTest: () => void;
  running: boolean;
  startTestSlow: () => void;
  stop: () => void;
}) {
  const [t] = useTranslation();
  const enabled = !running;
  return (
    <Grid container style={{ maxWidth: '50px' }}>
      <Grid item>
        <IconButton onClick={startTest} disabled={!enabled} title={t('test.button.title.run')}>
          <PlayIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={startTestSlow} disabled={!enabled} title={t('test.button.title.runSlow')}>
          <SlowMotionVideoIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={stop} disabled={!running} title={t('test.button.title.stop')}>
          <StopIcon />
        </IconButton>
      </Grid>
      <Grid item style={{ flexGrow: 1 }}></Grid>
    </Grid>
  );
}


function Given({ testCase, onAccChange }: { testCase: TestCaseOneInputOneOutput; onAccChange?: (u: Uint8) => void }) {
  const { t } = useTranslation();
  return <><Grid item xs={12}>
    <Typography variant="h6" style={{ fontFamily }}>
      {t('test.editor.given')}
    </Typography>
  </Grid>
    <Grid item xs={4}>
      <RegisterInput
        label="Acc register"
        value={testCase.acc}
        onChange={onAccChange}
      />
    </Grid>
    <Grid item xs={4}>
      <RegisterInputHexa
        value={testCase.acc}
      />
    </Grid></>
}

function RegisterInput({ value, label, onChange }: { label: string; value: Uint8; onChange?: (u: Uint8) => void }) {
  return <TextField
    type="number"
    label={label}
    variant="outlined"
    value={value.toNumber()}
    onChange={handleChange(onChange)}
    fullWidth
    size="small"
    onFocus={selectText}
  />
}
function RegisterInputHexa({ value }: { value: Uint8 }) {
  return <TextField
    type="text"
    label="hexadecimal"
    variant="outlined"
    value={`0x${value.toHex()}`}
    disabled
    fullWidth
    size="small"
  />
}

function When({ apeArchitecture, testCase, setInValues }: { apeArchitecture: ApeArchitecture; testCase: TestCaseOneInputOneOutput; setInValues?: (newInValue: Uint8[][]) => void }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const buttonPadding = theme.spacing(1);


  return <><Grid item xs={12}>
    <Typography variant="h6" style={{ fontFamily }}>
      {t('test.editor.when')}
    </Typography>
  </Grid>

    {testCase.input.map((inValue, idx) => (
      <WhenInputLine
        key={idx}
        apeArchitecture={apeArchitecture}
        values={inValue}
        onChange={(e) => {
          const newInValues = testCase.input.concat([]);
          newInValues[idx] = e;
          setInValues?.(newInValues);
        }}
        onUp={idx === 0 ? undefined :
          () => setInValues?.(switchCells(testCase.input, idx, idx - 1))
        }
        onRemove={testCase.input.length <= 1 ? undefined :
          () => {
            const newInValues = testCase.input.concat([]);
            newInValues.splice(idx, 1);
            setInValues?.(newInValues);
          }
        }
        onDown={idx === testCase.input.length - 1 ? undefined : () => setInValues?.(switchCells(testCase.input, idx, idx + 1))}

      />
    ))}

    <Grid item xs={1}>
      <IconButton
        onClick={() => setInValues?.(testCase.input.concat([[new Uint8(0)]]))}
        style={{ padding: buttonPadding }}
      >
        <Icon>add_circle</Icon>
      </IconButton>
    </Grid>
    <Grid item xs={11}></Grid></>
}

function WhenInputLine({ apeArchitecture, values, onChange, onUp, onDown, onRemove }:
  {
    apeArchitecture: ApeArchitecture;
    values: Uint8[];
    onChange: (newValue: Uint8[]) => void;
    onRemove?: () => void;
    onUp?: () => void;
    onDown?: () => void;
  }) {
  const theme = useTheme();
  const buttonPadding = theme.spacing(1);

  const handleChange = (idx: number, newValue: Uint8) => {
    const newValues = values.concat([]);
    newValues[idx] = newValue;
    onChange(newValues);
  };

  return <Grid item xs={12}>
    <Grid container spacing={2} style={{ marginBottom: theme.spacing(1) }}>
      <Grid item xs={8} style={{ borderLeft: `1px solid ${componentBorderColor}` }}>
        {apeArchitecture.input.map((input, idx) => <Grid container key={idx} spacing={2}>
          <Grid item xs={6}>
            <RegisterInput
              label={input}
              value={values[idx] || new Uint8(0)}
              onChange={(value) => handleChange(idx, value)}
            />
          </Grid>
          <Grid item xs={6}>
            <RegisterInputHexa
              value={values[idx] || new Uint8(0)}
            />
          </Grid></Grid>
        )}
      </Grid>
      <Grid item xs={4}>
        <IconButton
          disabled={!onRemove}
          onClick={onRemove}
          style={{ padding: buttonPadding }}
        >
          <Icon>remove_circle</Icon>
        </IconButton>
        <IconButton
          disabled={!onUp}
          onClick={onUp}
          style={{ padding: buttonPadding }}
        >
          <Icon>arrow_upward</Icon>
        </IconButton>
        <IconButton
          disabled={!onDown}
          onClick={onDown}
          style={{ padding: buttonPadding }}
        >
          <Icon>arrow_downward</Icon>
        </IconButton>
      </Grid>
    </Grid>
  </Grid>
    ;
}

function Then({ apeArchitecture, expected, setExpected }: { apeArchitecture: ApeArchitecture; expected: Uint8[]; setExpected?: (values: Uint8[]) => void }) {
  const { t } = useTranslation();

  const handleExpected = (idx: number, value: Uint8) => {
    const newValues = expected.concat([]);
    newValues[idx] = value;
    setExpected?.(newValues);
  };

  return <>
    <Grid item xs={12}>
      <Typography variant="h6" style={{ fontFamily }}>
        {t('test.editor.then')}
      </Typography>
    </Grid>
    {
      apeArchitecture.output.map((output, idx) =>
        <Grid item key={idx} xs={12}>
          <ExpectedOutput label={output} value={expected[idx] || new Uint8(0)} setValue={(value) => handleExpected(idx, value)} />
        </Grid>
      )
    }

  </>;
}