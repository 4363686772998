import { useState, useEffect } from 'react';
import axios from 'axios';

export type Config = {
  REACT_APP_AUDIENCE: string;
  REACT_APP_AUTH0_DOMAIN: string;
  REACT_APP_AUTH0_CLIENT_ID: string;
};

export function useConfig() {
  const [config, setConfig] = useState<Config | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadConfig() {
      const response = await axios.get<Config>('/api/config');
      setConfig(response.data);
      setLoading(false);
    }
    loadConfig();
  }, []);

  // const config = {
  //     REACT_APP_AUDIENCE: process.env.REACT_APP_AUDIENCE || 'https://algopolis-demo.lioda.xyz/',
  //     REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || 'lioda.eu.auth0.com',
  //     REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID || 'IcsjigMlubP7gHXDRb5zUXVa5LFuo3u6',
  //   };
  return { loading, config };
}
