import { CompilableAssemblyCode } from './compilable-assembly-code/compilable-assembly-code';
import { TestCaseOneInputOneOutput } from '../test/test-case';
import { Uint8 } from '../integer/uint8';

export enum Dialect {
  APE01 = "APE-01",
  APE02 = "APE-02",
}

export type ApeArchitecture = {
  input: string[];
  output: string[];
  registers: string[];
}


export const apeArchitectures: Record<Dialect, ApeArchitecture> = {
  [Dialect.APE01]: {
    input: ['in'],
    output: ['out'],
    registers: [],
  },
  [Dialect.APE02]: {
    input: ['in0', 'in1'],
    output: ['out0', 'out1'],
    registers: ['x0', 'x1'],
  }
};

export class LoadableFunction {

  constructor(
    readonly id: string,
    readonly label: string,
    readonly code: CompilableAssemblyCode,
    readonly testCases: TestCaseOneInputOneOutput[],
    readonly dialect: Dialect = Dialect.APE01,
  ) { }

  setId = (id: string) => new LoadableFunction(id, this.label, this.code, this.testCases, this.dialect);
  setLabel = (label: string) => new LoadableFunction(this.id, label, this.code, this.testCases, this.dialect);
  setCode = (code: CompilableAssemblyCode) => new LoadableFunction(this.id, this.label, code, this.testCases, this.dialect);
  addTest = (test: TestCaseOneInputOneOutput) => new LoadableFunction(this.id, this.label, this.code, [test].concat(this.testCases), this.dialect);
  setTest = (i: number, test: TestCaseOneInputOneOutput) => {
    const tests = this.testCases.concat();
    tests[i] = test;
    return new LoadableFunction(this.id, this.label, this.code, tests, this.dialect);
  };
  setDialect(dialect: Dialect) {
    const apeArchitecture = apeArchitectures[dialect];
    const testCases = this.testCases.map(testCase => {
      const input = testCase.input.map(input => apeArchitecture.input.map((_, idx) => input[idx] || new Uint8(0)));
      const expected = apeArchitecture.output.map((_, idx) => testCase.expected[idx] || new Uint8(0));

      return new TestCaseOneInputOneOutput(testCase.id, testCase.name, input, testCase.acc, expected)
    });

    return new LoadableFunction(this.id, this.label, this.code, testCases, dialect);
  }
}
