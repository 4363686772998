import { WireComponentType } from '../components/components';

export class WireDirections {
  private count = 0;
  private directions: [WireCardinalPoint, WireCardinalPoint] = ['none', 'none'];
  constructor(from: [WireCardinalPoint, WireCardinalPoint] = ['none', 'none']) {
    this.add(from[1]);
    this.add(from[0]);
  }
  add(direction: WireCardinalPoint) {
    if (direction === 'none') {
      return;
    }
    if (this.count === 0) {
      this.directions = [direction, 'none'];
    } else if (this.count === 1 && !this.isMostRecent(direction)) {
      this.directions = [direction, this.directions[0]];
    } else {
      if (this.isMostRecent(direction)) {
        return;
      }
      this.directions = [direction, this.directions[0]];
      return;
    }
    if (direction !== 'start') {
      ++this.count;
    }
  }
  asWire(): WireComponentType | undefined {
    if (this.contains('north', 'south')) {
      return 'verticalWire';
    } else if (this.contains('east', 'west')) {
      return 'horizontalWire';
    } else if (this.contains('north', 'none')) {
      return 'northWire';
    } else if (this.contains('north', 'east')) {
      return 'eastNorthWire';
    } else if (this.contains('north', 'west')) {
      return 'westNorthWire';
    } else if (this.contains('south', 'east')) {
      return 'eastSouthWire';
    } else if (this.contains('south', 'west')) {
      return 'westSouthWire';
    } else if (this.contains('south', 'none')) {
      return 'southWire';
    } else if (this.contains('north', 'none')) {
      return 'northWire';
    } else if (this.contains('east', 'none')) {
      return 'eastWire';
    } else if (this.contains('west', 'none')) {
      return 'westWire';
    } else if (this.contains('start')) {
      return 'startWire';
    }
    return undefined;
  }

  private contains(...wires: WireCardinalPoint[]): boolean {
    let result = true;
    for (const wire of wires) {
      result = result && (this.directions[0] === wire || this.directions[1] === wire);
    }
    return result;
  }
  private isMostRecent(direction: WireCardinalPoint): boolean {
    return this.directions[0] === direction;
  }
}

export type WireCardinalPoint = 'none' | 'start' | 'north' | 'east' | 'south' | 'west';

type Position = {
  row: number;
  col: number;
};

type ItemPosition = Position & {
  width: number;
  height: number;
};

const toNorth = (p: Position): Position => ({ row: p.row - 1, col: p.col });
const toSouth = (p: Position): Position => ({ row: p.row + 1, col: p.col });
const toEast = (p: Position): Position => ({ row: p.row, col: p.col + 1 });
// const toWest = (p: Position): Position => ({ row: p.row, col: p.col - 1 });
const matchPos = (p1: Position, p2: Position): boolean => p1.row === p2.row && p1.col === p2.col;
const isIn = (p: Position, dest: ItemPosition): boolean =>
  p.row >= dest.row && p.row < dest.row + dest.height && p.col >= dest.col && p.col < dest.col + dest.width;
const opposite = (cardinalPoint: WireCardinalPoint): WireCardinalPoint => {
  switch (cardinalPoint) {
    case 'north':
      return 'south';
    case 'south':
      return 'north';
    case 'east':
      return 'west';
    case 'west':
      return 'east';
    default:
      return 'none';
  }
};
const goTo = (cardinalPoint: WireCardinalPoint): [WireCardinalPoint, WireCardinalPoint] => [cardinalPoint, opposite(cardinalPoint)];

export function findCardinalFrom(src: ItemPosition, dest: ItemPosition): [WireCardinalPoint, WireCardinalPoint] {
  let source = src;
  let destination = dest;
  let reverse = false;
  if (src.width > 1) {
    reverse = true;
    source = dest;
    destination = src;
  }
  const north = toNorth(source);
  const south = toSouth(source);
  const east = toEast(source);
  if (matchPos(south, destination) || isIn(south, destination)) {
    return reverse ? goTo('north') : goTo('south');
  } else if (matchPos(north, destination) || isIn(north, destination)) {
    return reverse ? goTo('south') : goTo('north');
  } else if (matchPos(east, destination) || isIn(east, destination)) {
    return reverse ? goTo('west') : goTo('east');
  } else {
    return reverse ? goTo('east') : goTo('west');
  }
}
