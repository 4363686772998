import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

import { RegisterDirection, registerColor, registerWidth, registerHeight } from '../theme/register.setup';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { RegisterState } from '../../model/microprocessor/register/register-state';
import { RegisterType } from '../../model/types/register-type';

export type RegisterProps = {
  name: string;
  value: RegisterType;
  direction: RegisterDirection;
  state: RegisterState;
};

enum TerminatorStyle {
  Memory,
  Ender,
}

enum TerminatorSide {
  Right,
  Left,
}

const radius = '6px';

type TerminatorProperties = Pick<
  CreateCSSProperties<RegisterProps>,
  | 'borderLeftWidth'
  | 'borderLeftColor'
  | 'borderLeftStyle'
  | 'transition'
  | 'borderTop'
  | 'borderBottom'
  | 'borderRight'
  | 'borderTopLeftRadius'
  | 'borderBottomLeftRadius'
  | 'borderTopRightRadius'
  | 'borderBottomRightRadius'
>;

const useStyles = makeStyles(theme => {
  const drawArrowCss = ({ leftArrowColor, upAndDownColor }: { leftArrowColor: string; upAndDownColor: string }): TerminatorProperties => ({
    borderLeftColor: leftArrowColor,
    borderLeftStyle: 'solid',
    borderLeftWidth: registerWidth(0.6 * 0.33),
    transition: 'all 0.3s',
    borderTop: `${registerHeight(0.5)} solid ${upAndDownColor}`,
    borderBottom: `${registerHeight(0.5)} solid ${upAndDownColor}`,
    borderRight: '0',
  });

  const drawTerminator = (style: TerminatorStyle, side: TerminatorSide, registerColor: string): TerminatorProperties => {
    const borderLeftWidth = style !== TerminatorStyle.Memory ? '3px' : '1px';
    let borderRadius;
    if (side === TerminatorSide.Left) {
      borderRadius = { borderTopLeftRadius: radius, borderBottomLeftRadius: radius };
    } else {
      borderRadius = { borderTopRightRadius: radius, borderBottomRightRadius: radius };
    }
    return {
      ...borderRadius,
      borderLeftWidth,
      borderLeftColor: registerColor,
      borderLeftStyle: 'solid',
      transition: 'all 0.3s',
      borderTop: `${registerHeight(0.5)} solid ${registerColor}`,
      borderBottom: `${registerHeight(0.5)} solid ${registerColor}`,
      borderRight: '0',
    };
  };

  const arrowInput = (props: RegisterProps): CreateCSSProperties<RegisterProps> => {
    if (props.direction === RegisterDirection.Input) {
      return drawArrowCss({ leftArrowColor: 'transparent', upAndDownColor: registerColor(props.state).light });
    }
    return drawTerminator(
      props.direction === RegisterDirection.Memory ? TerminatorStyle.Memory : TerminatorStyle.Ender,
      TerminatorSide.Left,
      registerColor(props.state).light
    );
  };

  const arrowOutput = (props: RegisterProps): CreateCSSProperties<RegisterProps> => {
    if (props.direction === RegisterDirection.Output) {
      return drawArrowCss({ leftArrowColor: registerColor(props.state).light, upAndDownColor: 'transparent' });
    }
    return drawTerminator(
      props.direction === RegisterDirection.Memory ? TerminatorStyle.Memory : TerminatorStyle.Ender,
      TerminatorSide.Right,
      registerColor(props.state).light
    );
  };

  const centralValue = (props: RegisterProps): CreateCSSProperties<RegisterProps> => {
    const color = registerColor(props.state).light;
    return {
      alignContent: '',
      backgroundColor: color,
      color: theme.palette.getContrastText(color),
      transition: 'background 0.3s',
      paddingTop: '3px',
      paddingBottom: '3px',
    };
  };

  const typoValue = (props: RegisterProps): CreateCSSProperties<RegisterProps> => {
    const color = registerColor(props.state).dark;
    return {
      backgroundColor: color,
      color: theme.palette.getContrastText(color),
      borderRadius: '15px',
      fontFamily: 'Fira Code, Monospace',
      fontSize: '12px',
      transition: 'all 0.3s',
    };
  };
  const typoName = (props: RegisterProps): CreateCSSProperties<RegisterProps> => {
    const color = registerColor(props.state).light;
    return {
      color: theme.palette.getContrastText(color),
      fontFamily: 'Fira Code, Monospace',
      fontSize: '10px',
      textTransform: 'uppercase',
    };
  };

  return {
    arrowInput,
    arrowOutput,
    centralValue,
    typoValue,
    typoName,
  };
});

export function Register(props: RegisterProps) {
  const classes = useStyles(props);

  const label = props.value.render();

  const left = props.direction === RegisterDirection.Output ? 1 : 3;
  const right = props.direction === RegisterDirection.Input ? 1 : 3;
  const middle = props.direction === RegisterDirection.Memory ? 6 : 8;

  return (
    <Grid
      container
      alignItems="stretch"
      alignContent="center"
      style={{
        width: `${registerWidth(1)}`,
        maxHeight: `${registerHeight(1)}`,
        overflow: 'hidden',
      }}
    >
      <Grid item xs={left} className={classes.arrowInput}></Grid>
      <Grid item xs={middle} className={classes.centralValue}>
        <Grid container direction="column">
          <Grid item>
            <Typography align="center" className={classes.typoName}>
              {props.name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="center" className={classes.typoValue}>
              {label}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={right} className={classes.arrowOutput}></Grid>
    </Grid>
  );
}
