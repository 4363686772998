import React, { useState, useEffect, useMemo, useCallback } from 'react';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { MicrocontrollerCodeEditor } from './microcontroller-code-editor';
import { Uint8 } from '../../model/integer/uint8';
import { CompilableAssemblyCode } from '../../model/code/compilable-assembly-code/compilable-assembly-code';
import { TestCaseOneInputOneOutput } from '../../model/test/test-case';
import { Dialect, LoadableFunction } from '../../model/code/function';
import { AluState, TestResultDto, TestStatusDto } from '../../model/test/test.dto';
import { EditorFocus } from '../../library/code-editor';
import { useMessages } from '../../layout/snackbars';

const useTester = () => {
  const dialectMap = {
    [Dialect.APE01]: 'ape01',
    [Dialect.APE02]: 'ape02',
  };
  return (dialect: Dialect, code: CompilableAssemblyCode, test: TestCaseOneInputOneOutput) => {
    return axios.post<TestResultDto>(`/api/${dialectMap[dialect]}/test`, {
      ...code,
      test: {
        input: test.input.map((u8s) => u8s.map(u8 => u8.toNumber())),
        acc: test.acc.toNumber(),
        expectedU8: test.expected.map(u => u.toNumber()),
      },
    });
  };
};

export type IdeTesterProps = {
  setAlertError: (error: boolean) => void;
  loadableFunction?: LoadableFunction;
  focus?: EditorFocus;
  replaceFunction: (f: LoadableFunction) => void;
  deleteFunction: (f: LoadableFunction) => void;
  onClose?: () => void;
};

type State = {
  ip: number;
  acc: number;
  input: number[];
  output: number[];
  registers: number[];
  sourceLineNumber: number;
};

const initialState: State = { ip: 0, acc: 0, input: [0], output: [0], sourceLineNumber: 0, registers: new Array(2).fill(0) };

let interval: NodeJS.Timeout | undefined = undefined;

type RunningTestResultDto = {
  states: AluState[];
  status: TestStatusDto;
};

type RunningData = {
  testResult: RunningTestResultDto;
  testId: string;
};

function cloneStatus(statuses: Map<string, boolean | undefined>): Map<string, boolean | undefined> {
  return new Map(statuses.entries());
}

export function IdeTester(props: IdeTesterProps) {
  const [t] = useTranslation();
  const tester = useTester();
  const messages = useMessages();
  const [running, setRunning] = useState(false);
  const [state, setState] = useState(initialState);
  const [statuses, setStatuses] = useState(new Map<string, boolean | undefined>());
  const [runningStates, setRunningStates] = useState(undefined as RunningData | undefined);
  const [compileStatus, setCompileStatus] = useState({ display: false, lines: [] as number[] });

  const highlight = useMemo(() => {
    return !running ? undefined : new Uint8(state.sourceLineNumber);
  }, [running, state]);

  const testAll = async () => {
    if (!props.loadableFunction) return;
    const funct = props.loadableFunction;
    let failed = 0;
    let succeed = 0;
    const newStatuses = new Map<string, boolean | undefined>();
    setStatuses(newStatuses);
    setCompileStatus({ display: false, lines: [] });
    try {
      const isOk = await Promise.all(
        props.loadableFunction.testCases.map(async (t, idx) => {
          const result = await tester(funct.dialect, funct.code, t);
          if (result.status !== 200 || !result.data) {
            throw new Error();
          }
          const testResult = result.data;
          if (testResult.compileErrors && testResult.compileErrors.length) {
            return testResult.compileErrors;
          }
          newStatuses.set(t.id, testResult.status?.success);
          if (testResult.status?.success) {
            succeed += 1;
          } else {
            failed += 1;
          }
          setStatuses(newStatuses);
        })
      );
      const errorLines = isOk.find((result) => result !== undefined);
      if (errorLines) {
        return setCompileStatus({ display: true, lines: errorLines });
      }

      messages.display({
        severity: failed === 0 ? 'success' : 'error',
        message: t('test.run.all', { succeed, failed }),
      });
    } catch (e) {
      props.setAlertError(true);
    }
  };

  const newStatus = useCallback(
    (testId: string, testStatus: TestStatusDto | undefined) => {
      const newStatuses = cloneStatus(statuses);
      newStatuses.set(testId, testStatus?.success);
      setStatuses(newStatuses);
      messages.display(testStatus?.success ? { severity: 'success', message: t('test.run.success') } : {
        severity: 'error', message: t('test.run.fail', {
          expected: testStatus?.expectedU8,
          actual: testStatus?.actualU8,
        })
      });
    },
    [statuses, messages, t]
  );

  const test = async (slow: boolean, dialect: Dialect, code: CompilableAssemblyCode, test: TestCaseOneInputOneOutput): Promise<void> => {
    const newStatuses = cloneStatus(statuses);
    newStatuses.set(test.id, undefined);
    setStatuses(newStatuses);
    setCompileStatus({ display: false, lines: [] });
    try {
      const result = await tester(dialect, code, test);
      if (result.status !== 200 || !result.data) {
        props.setAlertError(true);
        return;
      }
      const testResult = result.data;
      if (testResult.compileErrors && testResult.compileErrors.length) {
        setCompileStatus({ display: true, lines: testResult.compileErrors });
        return;
      }
      if (!slow || !testResult.states || !testResult.status || testResult.executionError) {
        if (!testResult.states || testResult.executionError) {
          messages.display({ severity: 'error', message: testResult.executionError ? t(testResult.executionError) : '' });
          return;
        }
        newStatus(test.id, testResult.status);
        const finalState = testResult.states.pop();
        finalState && setState(finalState);
        return;
      }
      const states = testResult.states;
      const status = testResult.status;
      setRunning(true);
      setRunningStates({ testResult: { states, status }, testId: test.id });
    } catch (e) {
      props.setAlertError(true);
    }
  };

  useEffect(() => {
    if (interval) {
      clearInterval(interval);
      interval = undefined;
    }
    if (!running || !runningStates || !runningStates.testResult.states.length) {
      setState(initialState);
      return;
    }
    let cur = 0;
    const nextState = () => {
      const curState = runningStates.testResult.states[cur++];
      if (cur === runningStates.testResult.states.length) {
        newStatus(runningStates.testId, runningStates.testResult.status);
        setRunning(false);
      }
      setState(curState);
    };
    nextState();
    interval = setInterval(nextState, 1000);
    return () => interval && clearInterval(interval);
  }, [runningStates, running, newStatus]);

  return (
    <MicrocontrollerCodeEditor
      outputValue={state.output.map(value => new Uint8(value))}
      inputValue={state.input.map(value => new Uint8(value))}
      registers={state.registers.map(value => new Uint8(value))}
      highlight={highlight}
      statuses={statuses}
      ip={new Uint8(state.ip)}
      acc={new Uint8(state.acc)}
      running={running}
      loadableFunction={props.loadableFunction}
      onEdit={props.replaceFunction}
      onDelete={props.deleteFunction}
      run={async (slow: boolean, testCase: TestCaseOneInputOneOutput) => {
        if (!props.loadableFunction) return;
        test(slow, props.loadableFunction.dialect, props.loadableFunction.code, testCase);
      }}
      runAll={testAll}
      stop={() => setRunning(false)}
      errors={compileStatus.lines}
      onClose={props.onClose}
      editorFocus={props.focus}
    />
  );
}
