import { WireDirections } from '../circuit/wire-directions';

export type ArrowType = 'up' | 'left' | 'right' | 'down';
type Wires = 'horizontalWire' | 'verticalWire' | 'eastNorthWire' | 'eastSouthWire' | 'westNorthWire' | 'westSouthWire';
type DrawedWires = 'northWire' | 'southWire' | 'eastWire' | 'westWire' | 'startWire';
export type CircuitComponentType =
  | Wires
  | 'ape01'
  | 'ape02'
  | 'temp'
  | 'frost'
  | 'warn'
  | 'disabled'
  | 'empty'
  | 'timer'
  | 'carCounter'
  | 'oscillo'
  | 'trafficLight'
  | 'dehumid' | 'humid'
  | DrawedWires;

export interface CircuitComponent {
  componentType: CircuitComponentType;
  // wireDirections?: WireDirections;
  id?: string;
  functionId?: string;
}

export type WireComponentType = Wires | DrawedWires;

export function isWire(type: CircuitComponentType): boolean {
  return (
    [
      'horizontalWire',
      'verticalWire',
      'eastNorthWire',
      'eastSouthWire',
      'westNorthWire',
      'westSouthWire',
      'northWire',
      'southWire',
      'eastWire',
      'westWire',
    ].indexOf(type) >= 0
  );
}

export function asDirections(type: CircuitComponentType): WireDirections | undefined {
  switch (type) {
    case 'horizontalWire':
      return new WireDirections(['east', 'west']);
    case 'verticalWire':
      return new WireDirections(['north', 'south']);
    case 'eastNorthWire':
      return new WireDirections(['east', 'north']);
    case 'eastSouthWire':
      return new WireDirections(['east', 'south']);
    case 'westNorthWire':
      return new WireDirections(['west', 'north']);
    case 'westSouthWire':
      return new WireDirections(['west', 'south']);
    case 'northWire':
      return new WireDirections(['north', 'none']);
    case 'southWire':
      return new WireDirections(['south', 'none']);
    case 'eastWire':
      return new WireDirections(['east', 'none']);
    case 'westWire':
      return new WireDirections(['west', 'none']);
  }
  return undefined;
}
