import React, { CSSProperties } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTint, faTintSlash, faTrafficLight } from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as Ape01 } from './ape01.svg';
import { ReactComponent as MapApe01 } from './map-ape01.svg';
import { ReactComponent as Ape02 } from './ape02.svg';
import { ReactComponent as MapApe02 } from './map-ape02.svg';
import { ReactComponent as Wire } from './wire.svg';
import { ReactComponent as VerticalWire } from './vertical-wire.svg';
import { ReactComponent as EastNorthWire } from './east-north-wire.svg';
import { ReactComponent as EastSouthhWire } from './east-south-wire.svg';
import { ReactComponent as WestNorthWire } from './west-north-wire.svg';
import { ReactComponent as WestSouthWire } from './west-south-wire.svg';
import { ReactComponent as MapVerticalWire } from './map-vertical-wire.svg';
import { ReactComponent as MapEastNorthWire } from './map-east-north-wire.svg';
import { ReactComponent as MapEastSouthhWire } from './map-east-south-wire.svg';
import { ReactComponent as MapWestNorthWire } from './map-west-north-wire.svg';
import { ReactComponent as MapWestSouthWire } from './map-west-south-wire.svg';
import { ReactComponent as ThumbWire } from './thumb-wire.svg';
import { ReactComponent as Temp } from './temp-sensor.svg';
import { ReactComponent as CarCounter } from './carcounter.svg';
import { ReactComponent as Humid } from './humid-sensor.svg';
import { ReactComponent as Dehumid } from './dehumid.svg';
import { ReactComponent as TrafficLight } from './trafficlight.svg';
import { ReactComponent as FrostSignal } from './frost-signal.svg';
import { ReactComponent as WarnSignal } from './warn-signal.svg';
import { ReactComponent as Oscillo } from './sq-wave.svg';
import { ReactComponent as MapOscillo } from './map-sq-wave.svg';
import { ReactComponent as Timer } from './timer.svg';
import { ReactComponent as MapTimer } from './map-timer.svg';
import { ReactComponent as WireStart } from './wire_start.svg';
import { ReactComponent as WireSouth } from './wire_south.svg';
import { ReactComponent as WireNorth } from './wire_north.svg';
import { ReactComponent as WireWest } from './wire_west.svg';
import { ReactComponent as WireEast } from './wire_east.svg';
import { CircuitComponentType, CircuitComponent } from '../../../model/components/components';

export type ItemActions = 'delete' | 'load-function';

const style: CSSProperties = {
  width: '100%',
  height: '100%',
  stroke: 'none',
  fill: 'currentColor',
  // pointerEvents: 'none',
};

export type CatalogItem = {
  img: React.ReactNode;
  thumb?: React.ReactNode;
  mapImg?: React.ReactNode;
  width: number;
  height: number;
  actions?: ItemActions[];
  canBeEmpty?: boolean;
};

export const circuitComponentTypeCatalog: Partial<Record<CircuitComponentType, CatalogItem>> = {
  horizontalWire: {
    img: <Wire style={style} />,
    thumb: <ThumbWire style={style} />,
    width: 1,
    height: 1,
    actions: ['delete'],
  },
  verticalWire: {
    img: <VerticalWire style={style} />,
    thumb: <MapVerticalWire style={style} />,
    width: 1,
    height: 1,
    actions: ['delete'],
  },
  eastNorthWire: {
    img: <EastNorthWire style={style} />,
    thumb: <MapEastNorthWire style={style} />,
    width: 1,
    height: 1,
    actions: ['delete'],
  },
  eastSouthWire: {
    img: <EastSouthhWire style={style} />,
    thumb: <MapEastSouthhWire style={style} />,
    width: 1,
    height: 1,
    actions: ['delete'],
  },
  westNorthWire: {
    img: <WestNorthWire style={style} />,
    thumb: <MapWestNorthWire style={style} />,
    width: 1,
    height: 1,
    actions: ['delete'],
  },
  westSouthWire: {
    img: <WestSouthWire style={style} />,
    thumb: <MapWestSouthWire style={style} />,
    width: 1,
    height: 1,
    actions: ['delete'],
  },
  ape01: {
    img: <Ape01 style={style} />,
    thumb: (
      <div draggable>
        <MapApe01 style={style} />
      </div>
    ),
    width: 4,
    height: 3,
    actions: ['load-function', 'delete'],
    canBeEmpty: true,
  },
  ape02: {
    img: <Ape02 style={style} />,
    thumb: (
      <div draggable>
        <MapApe02 style={style} />
      </div>
    ),
    width: 4,
    height: 3,
    actions: ['load-function', 'delete'],
    canBeEmpty: true,
  },
  temp: {
    img: <Temp style={style} />,
    width: 1,
    height: 1,
  },
  frost: {
    img: <FrostSignal style={style} />,
    width: 1,
    height: 1,
  },
  warn: {
    img: <WarnSignal style={style} />,
    mapImg: <FontAwesomeIcon icon={faExclamationTriangle} style={style} />,
    width: 1,
    height: 1,
  },
  trafficLight: {
    img: <TrafficLight style={style} />,
    mapImg: <FontAwesomeIcon icon={faTrafficLight} style={style} />,
    width: 1,
    height: 1,
  },
  dehumid: {
    img: <Dehumid style={style} />,
    mapImg: <FontAwesomeIcon icon={faTintSlash} style={style} />,
    width: 1,
    height: 1,
  },
  humid: {
    img: <Humid style={style} />,
    mapImg: <FontAwesomeIcon icon={faTint} style={style} />,
    width: 1,
    height: 1,
  },
  oscillo: {
    img: <Oscillo style={style} />,
    mapImg: <MapOscillo style={style} />,
    width: 1,
    height: 1,
  },
  timer: {
    img: <Timer style={style} />,
    mapImg: <MapTimer style={style} />,
    width: 1,
    height: 1,
  },
  carCounter: {
    img: <CarCounter style={style} />,
    mapImg: <MapTimer style={style} />,
    width: 1,
    height: 1,
  },
  startWire: {
    img: <WireStart style={style} />,
    width: 1,
    height: 1,
    actions: ['delete'],
  },
  northWire: {
    img: <WireNorth style={style} />,
    width: 1,
    height: 1,
    actions: ['delete'],
  },
  southWire: {
    img: <WireSouth style={style} />,
    width: 1,
    height: 1,
    actions: ['delete'],
  },
  westWire: {
    img: <WireWest style={style} />,
    width: 1,
    height: 1,
    actions: ['delete'],
  },
  eastWire: {
    img: <WireEast style={style} />,
    width: 1,
    height: 1,
    actions: ['delete'],
  },
};

export function circuitComponentTypeCatalog2(comp: CircuitComponent): CatalogItem | undefined {
  return circuitComponentTypeCatalog[comp.componentType];
}
//   if (comp.componentType !== 'wire') {
//     return circuitComponentTypeCatalog[comp.componentType];
//   }
//   let img = <WireStart style={style} />;

//   const cancelDrag = (e: React.DragEvent<SVGSVGElement>) => {
//     e.preventDefault();
//     e.stopPropagation();
//   };

//   if (comp.wireDirections) {
//     switch (comp.wireDirections.asWire()) {
//       case 'Horizontal':
//         img = <Wire style={style} onDragStart={cancelDrag} />;
//         break;
//       case 'Vertical':
//         img = <VerticalWire style={style} onDragStart={cancelDrag} />;
//         break;
//       case 'East':
//         img = <WireEast style={style} onDragStart={cancelDrag} />;
//         break;
//       case 'West':
//         img = <WireWest style={style} onDragStart={cancelDrag} />;
//         break;
//       case 'North':
//         img = <WireNorth style={style} onDragStart={cancelDrag} />;
//         break;
//       case 'South':
//         img = <WireSouth style={style} onDragStart={cancelDrag} />;
//         break;
//       case 'EastNorth':
//         img = <EastNorthWire style={style} onDragStart={cancelDrag} />;
//         break;
//       case 'EastSouth':
//         img = <EastSouthhWire style={style} onDragStart={cancelDrag} />;
//         break;
//       case 'WestNorth':
//         img = <WestNorthWire style={style} onDragStart={cancelDrag} />;
//         break;
//       case 'WestSouth':
//         img = <WestSouthWire style={style} onDragStart={cancelDrag} />;
//         break;
//     }
//   }
//   const item: CatalogItem = {
//     img: img,
//     width: 1,
//     height: 1,
//     actions: ['delete'],
//   };
//   return item;
// }
