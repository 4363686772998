import React, { useState } from 'react';

import { ChallengesRepository } from '../../model/challenges/challenge-repository';

export let useChallengesRepository: () => ChallengesRepository = () => {
  throw new Error('The component is not a children of ChallengesRepositoryProvider');
};

export function ChallengesRepositoryProvider({ children }: { children: React.ReactNode }) {
  const [repository] = useState(new ChallengesRepository());
  useChallengesRepository = () => repository;
  return <>{children}</>;
}
