import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  useTheme,
  ListItem,
  List,
  ListItemText,
  Collapse,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';

import { componentBorderColor, fontFamily } from '../theme/component.setup';
import { ReactComponent as HelpDialects } from '../circuit/ui/help-dialect.svg';
import { grey } from '@material-ui/core/colors';
import { Markdown } from '../text/markdown';

type InstructionDto = {
  name: string;
  usages: string[];
  description: string;
};

type DocumenationModalprops = {
  open: boolean;
  onClose: () => void;
};

const height = '90vh';

function contains(openIds: string[], id: string) {
  return !!openIds.find((s) => s === id);
}

const useStyles = makeStyles((theme) => ({
  instruction: { paddingLeft: theme.spacing(4), paddingTop: 0, paddingBottom: 0 },
  closeButton: {
    color: grey[500],
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

export function DialectDocumentationButton() {
  const theme = useTheme();
  const [openDocumentation, setOpenDocumentation] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <IconButton title={t('header.language-documentation')} onClick={() => setOpenDocumentation(true)}>
        <HelpDialects
          style={{
            width: '24px',
            height: '24px',
            stroke: 'none',
            fill: theme.palette.common.white,
          }}
        />
      </IconButton>
      <DocumentationModal open={openDocumentation} onClose={() => setOpenDocumentation(false)} />
    </>
  );
}

function DocumentationModal(props: DocumenationModalprops) {
  const { t } = useTranslation('dialects');
  const classes = useStyles();

  return (
    <>
      <Dialog fullWidth maxWidth="lg" open={props.open} onClose={props.onClose}>
        <DialogTitle id="max-width-dialog-title">
          {t('title')}
          <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ height, maxHeight: height }}>
          <Help />
        </DialogContent>
      </Dialog>
    </>
  );
}

function Help() {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation('dialects');
  const [openApes, setOpenApes] = useState<string[]>(['APE-01']);
  const [instruction, setInstruction] = useState<InstructionDto | undefined>(undefined);


  const handle = (id: string) => {
    if (contains(openApes, id)) {
      setOpenApes(openApes.filter((s) => s !== id));
    } else {
      setOpenApes(openApes.concat([id]));
    }
  };

  type DialectHelpDto = {
    id: string;
    instructions: Record<string, {
      usages: string[];
      description: string;
    }>;
  };

  const apes = t<Record<string, DialectHelpDto>>('dialects', { returnObjects: true });

  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item style={{ flexBasis: '20%', borderRight: `1px solid ${componentBorderColor}`, height: '100%' }}>
        <List component="nav">
          {Object.values(apes).map((d) => (
            <div key={d.id}>
              <ListItem button onClick={() => handle(d.id)}>
                <ListItemText primary={d.id}></ListItemText>
                {contains(openApes, d.id) ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={contains(openApes, d.id)}>
                <List>
                  {Object.keys(d.instructions)
                    .sort((i1, i2) => i1.localeCompare(i2))
                    .map((instrName) => (
                      <ListItem
                        key={instrName}
                        button
                        onClick={() => setInstruction({ name: instrName, ...d.instructions[instrName] })}
                        className={classes.instruction}
                      >
                        <ListItemText
                          primary={instrName}
                          primaryTypographyProps={{
                            style: { fontFamily: fontFamily, fontSize: '0.9em' },
                          }}
                          style={{ padding: `0 ${theme.spacing(4)}` }}
                        />
                      </ListItem>
                    ))}
                </List>
              </Collapse>
            </div>
          ))}
        </List>
      </Grid>
      <Grid item style={{ flexGrow: 1, marginLeft: theme.spacing(2) }}>
        <Display instr={instruction} />
      </Grid>
    </Grid>
  );
}

function Display({ instr, }: { instr?: InstructionDto }) {
  const { t } = useTranslation('dialects');
  if (!instr) {
    return <Markdown>{t('description')}</Markdown>;
  }
  const template = `## ${instr?.name}

Usages:
${instr.usages.map((usage) => `* \`${usage}\``).join('\n')}


${instr.description}
  
  `;
  return <Markdown>{template}</Markdown>;
}

