import React, { useState, useEffect, useContext } from 'react';

import { makeStyles, Card, CardContent, Typography, Grid, CardHeader, CardActionArea, useTheme, Divider } from '@material-ui/core';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useHistory } from 'react-router-dom';

import { componentBorderColor, useComponentStyles } from '../components/theme/component.setup';
import { useTranslation } from 'react-i18next';
import { HeaderLayout } from '../components/theme/header-layout';
import { IconDto, ChallengeTableOfContents, ChallengeCategory } from '../model/challenges/challenge-repository';
import { useChallengesRepository } from '../components/dependencies/challenges-repository-provider';
import { grey } from '@material-ui/core/colors';
import { IconChallenge } from '../components/icons/challenge-icon';
import { UserContext } from '../components/user/user-provider';
import { DatabaseFactory } from '../model/database/database';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    maxWidth: 275,
    maxHeight: 149,
    minHeight: 149,
  },
  frame: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: `calc(100vw - ${theme.spacing(2) * 2}px)`,
  },
  cardHeader: {
    flexDirection: 'row-reverse',
    minHeight: 93,
    maxHeight: 93,
  },
  cardIcon: {
    textAlign: 'center',
    marginTop: 0,
    paddingTop: 0,
  },
  title: {
    color: componentBorderColor,
    textShadow: `0 0 10px ${componentBorderColor}`,
  },
  disabledTitle: {
    color: grey[700],
  },
}));

export function Levels() {
  const currentUser = useContext(UserContext);
  const classes = useStyles();
  const challengesRepository = useChallengesRepository();
  const [challenges, setChallenges] = useState<ChallengeTableOfContents>({ categories: [] });
  const [allStatus, setAllStatus] = useState<Map<string, boolean>>(new Map());

  useEffect(() => {
    const database = DatabaseFactory(currentUser.user);
    async function load() {
      const all = await challengesRepository.getChallengeList();
      const tuplesStatus = await Promise.all(
        all.categories.flatMap((cat) =>
          cat.challenges.map(async (challenge) => {
            return [challenge.id, await database.getStatus(challenge.id)] as [string, boolean];
          })
        )
      );
      setChallenges(all);
      setAllStatus(tuplesStatus.reduce((res, tuple) => res.set(tuple[0], tuple[1]), new Map<string, boolean>()));
    }
    load();
  }, [challengesRepository, currentUser.user]);

  return (
    <HeaderLayout>
      <Typography variant="h2" component="h1" gutterBottom className={classes.frame}>
        Challenges
      </Typography>
      {challenges.categories.length ? (
        challenges.categories.map((category) => <Category key={category.id} category={category} allStatus={allStatus} />)
      ) : (
          <Category allStatus={allStatus} />
        )}
    </HeaderLayout>
  );
}

function isDisplayed(allStatus: Map<string, boolean>, id?: string): boolean {
  if (!id) {
    return true;
  }
  return allStatus.get(id) || false;
}

function Category({ category, allStatus }: { category?: ChallengeCategory; allStatus: Map<string, boolean> }) {
  const [t] = useTranslation('challenges');
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid container direction="column" className={classes.frame}>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item style={{ marginTop: theme.spacing(2) }}>
        <CategoryHeader category={category} />
      </Grid>
      <Grid item style={{ marginTop: theme.spacing(2) }}>
        <Grid container spacing={3} >
          {category ? (
            category.challenges.map((c, idx, all) =>
              isDisplayed(allStatus, all[idx - 1]?.id) ? <Grid key={c.id} item data-tour="challenge01">
                <LevelCard
                  num={idx + 1}
                  title={t(`levels.${c.id}.title`)}
                  subtitle={t(`levels.${c.id}.subtitle`)}
                  link={`/challenge/${c.id}`}
                  icon={c.icon}
                  succeed={allStatus.get(c.id) || false}
                />
              </Grid> : undefined
            )
          ) : (
              <Grid item>
                <Skeleton variant="rect" width={275} height={150} />
              </Grid>
            )}
        </Grid>
      </Grid>
    </Grid>
  );
}

function CategoryHeader({ category }: { category?: ChallengeCategory }) {
  const [t] = useTranslation('challenges');
  return (
    <Grid container spacing={6} style={{ alignItems: 'flex-end' }}>
      <Grid item>
        <Typography variant="h4" component="h2">
          {category ? t(`category.${category.id}.title`) : <Skeleton width={200} />}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" color="textSecondary">
          {category ? t(`category.${category.id}.subtitle`) : <Skeleton width={150} />}
        </Typography>
      </Grid>
    </Grid>
  );
}

function LevelCard({
  num,
  title,
  subtitle,
  link,
  icon,
  succeed,
  disabled,
}: {
  num: number;
  title: string;
  subtitle: string;
  link: string;
  icon: IconDto;
  succeed: boolean;
  disabled?: boolean;
}) {
  const history = useHistory();
  const classes = useStyles();
  const [t] = useTranslation('challenges');
  const componentClasses = useComponentStyles(useTheme());
  let avatar: React.ReactNode;
  if (disabled) {
    avatar = <></>;
  } else if (succeed) {
    avatar = <StarIcon style={{ color: componentBorderColor }} />;
  } else {
    avatar = <StarBorderIcon color="disabled" />;
  }
  return (
    <Card variant="outlined" className={`${disabled ? '' : componentClasses.componentBorder} ${classes.root}`}>
      <CardActionArea disabled={disabled} onClick={() => history.push(link)}>
        <CardHeader
          classes={{ root: classes.cardHeader, title: disabled ? '' : classes.title }}
          title={`${num}. ${t(title)}`}
          avatar={avatar}
          subheader={t(subtitle)}
        />
        <CardContent className={classes.cardIcon}>
          <IconChallenge icon={icon} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
//border: 0, padding: 0, margin: 0, position: 'relative', bottom: 0,
