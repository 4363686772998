import { LoadableFunction } from '../code/function';
import { User } from '../user/user';
import { Blueprint } from '../challenges/challenge-repository';
import { HybridStorageDatabase } from './hybrid-database';

export type ChallengeCircuitCustomized = {
  challengeId: string;
  circuitId: string;
  blueprint: Blueprint;
};

export interface Database {
  readonly user: User;
  getStatus(challengeId: string): Promise<boolean>;
  setStatus(challengeId: string, status: boolean): Promise<void>;

  loadFunctions(): Promise<LoadableFunction[]>;
  storeFunctions(functions: LoadableFunction[]): Promise<void>;

  loadCircuitForChallenge(challengeId: string, circuitId: string): Promise<ChallengeCircuitCustomized>;
  storeChallengeCircuit(circuit: ChallengeCircuitCustomized): Promise<void>;
  resetCircuit(circuit: ChallengeCircuitCustomized): Promise<ChallengeCircuitCustomized>;

  displaySubscribeMessage(): Promise<boolean>;
}

export const DatabaseFactory = (user: User): Database => new HybridStorageDatabase(user);
