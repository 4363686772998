import React, { useState, useContext, useEffect } from 'react';

import { Paper, Toolbar, IconButton, makeStyles, Grid, Divider, useTheme } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import PlayIcon from '@material-ui/icons/PlayArrow';
import { grey } from '@material-ui/core/colors';

import { ChallengeCircuitDefinition } from '../../model/challenges/challenge-repository';
import { ToolbarThumbComponent } from '../circuit/toolbar-thumb';
import { useComponentStyles } from '../theme/component.setup';
import { colSize, rowSize } from '../circuit/circuit-component';
import { useChallengeStyles } from './challenge-theme';
import { CircuitComponentType, CircuitComponent } from '../../model/components/components';
import { useTranslation } from 'react-i18next';
import { useChallengeTabContext } from '../challenge-tabs/spawning-challenge-tabs';
import { BlueprintComponents2 } from './challenge-circuit-blueprint-components2';
import { UserContext } from '../user/user-provider';
import { useDatabase } from '../dependencies/use-database';
import { ChallengeCircuitCustomized } from '../../model/database/database';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles({
  blueprintGridCell: {
    width: `${colSize.size}${colSize.unit}`,
    height: `${rowSize.size}${rowSize.unit}`,
    padding: 0,
  },
  blueprintGridCellDisabled: {
    width: `${colSize.size}${colSize.unit}`,
    height: `${rowSize.size}${rowSize.unit}`,
    padding: 0,
    backgroundColor: grey[700],
    opacity: 0.4,
  },
});

export function ChallengeCircuit({ challengeId, initialCircuit }: { challengeId: string; initialCircuit: ChallengeCircuitDefinition }) {
  const currentUser = useContext(UserContext);
  const database = useDatabase(currentUser.user);
  const challengeStyles = useChallengeStyles();
  const [circuit, updateCircuit] = useState<ChallengeCircuitCustomized | undefined>(undefined);
  const [allowedComponentTypes, setAllowedComponentTypes] = useState<[CircuitComponent, number | null][] | undefined>(undefined);
  const [selectedComponentType, setSelectedComponentType] = useState<CircuitComponentType | undefined>(undefined);

  useEffect(() => {
    setAllowedComponentTypes(initialCircuit.allowedComponentTypes);
    async function loadCircuit() {
      try {
        const circuit = await database.loadCircuitForChallenge(challengeId, initialCircuit.id);
        updateCircuit(circuit);
      } catch (e) {
        // Nothing to do
      }
    }
    loadCircuit();
  }, [database, challengeId, initialCircuit]);

  const setCircuit = async (circuitUpdated: ChallengeCircuitCustomized) => {
    updateCircuit(circuitUpdated);
    await database.storeChallengeCircuit(circuitUpdated);
  };

  const resetCircuit = async () => {
    if (!circuit) {
      return;
    }
    const reset = await database.resetCircuit(circuit);
    updateCircuit(reset);
  };

  // const resetCircuit = (toolbarOnly?: boolean) => {
  //   if (toolbarOnly) {
  //     return setCircuit({ ...circuit, allowedComponentTypes: initialCircuit.allowedComponentTypes });
  //   }
  //   const newCircuit = Object.assign(initialCircuit, {});
  //   setCircuit(newCircuit);
  // };

  const handleSetComponent = (added: { row: number; col: number; type: CircuitComponentType }[]) => {
    // { ...circuit, blueprint: circuit.blueprint.cell(row, col, type) }
    // const added = addedAll.filter((e) => isWire(e.type));
    if (!circuit) return;
    let modified = false;
    const newCircuit = added.reduce(
      (c, add) => ({ ...c, blueprint: c.blueprint.cell(add.row, add.col, add.type, () => (modified = true)) }),
      { ...circuit }
    );
    if (!modified) return;
    setCircuit(newCircuit);
    setSelectedComponentType(undefined);
  };
  const modifyComponent = (row: number, col: number, comp: CircuitComponent) => {
    if (!circuit) return;
    setCircuit({ ...circuit, blueprint: circuit.blueprint.replaceCell(row, col, comp) });
  };
  const removeComponent = (row: number, col: number) => {
    if (!circuit) return;
    setCircuit({
      ...circuit,
      blueprint: circuit.blueprint.cell(row, col, 'empty', () => {
        /**/
      }),
    });
  };

  return (
    <>
      <Paper
        variant="outlined"
        className={challengeStyles.tab}
        data-tour="challenge-circuit"
        onDragEnd={(e) => setSelectedComponentType(undefined)}
      >
        <CircuitToolbar
          types={allowedComponentTypes}
          placedComponents={circuit?.blueprint.countTypes()}
          setSelectedComponentType={setSelectedComponentType}
          selectedComponentType={selectedComponentType}
          resetCircuit={resetCircuit}
        />
        <BlueprintGrid countCols={circuit?.blueprint.width} countRows={circuit?.blueprint.height} cells={circuit?.blueprint.cells} />
        <BlueprintComponents2
          countCols={circuit?.blueprint.width}
          countRows={circuit?.blueprint.height}
          cells={circuit?.blueprint.cells}
          shadowComponentType={selectedComponentType}
          releaseComponentType={() => setSelectedComponentType(undefined)}
          addComponent={handleSetComponent}
          removeComponent={removeComponent}
          modifyComponent={modifyComponent}
        />
      </Paper>
    </>
  );
}

export function CircuitToolbar({
  types,
  resetCircuit,
  selectedComponentType,
  setSelectedComponentType,
  placedComponents,
}: {
  types?: [CircuitComponent, number | null][];
  resetCircuit: (toolbarOnly?: boolean) => void;
  selectedComponentType?: CircuitComponentType;
  setSelectedComponentType: (selected?: CircuitComponentType) => void;
  placedComponents?: Map<CircuitComponentType, number>;
}) {
  const theme = useTheme();
  const [t] = useTranslation();
  const tabsContext = useChallengeTabContext();

  if (!types || !placedComponents) {
    return <Skeleton variant="rect" height={24} />;
  }

  return (
    <Toolbar data-tour="challenge01-circuit-components">
      <IconButton
        style={{ marginRight: theme.spacing(3) }}
        title={t('run.tryit')}
        onClick={() => {
          tabsContext.runChallenge();
        }}
      >
        <PlayIcon />
      </IconButton>
      <Divider orientation="vertical" flexItem />
      {types.map((comp, i) => {
        if (!Array.isArray(comp)) {
          resetCircuit(true);
          return <></>;
        }
        return (
          <ToolbarThumbComponent
            key={i}
            type={comp[0].componentType}
            max={comp[1]}
            placed={placedComponents.get(comp[0].componentType)}
            selected={comp[0].componentType === selectedComponentType}
            onMouseDown={() => {
              const selected = comp[0].componentType === selectedComponentType;
              if (selected) {
                setSelectedComponentType(undefined);
              } else {
                setSelectedComponentType(comp[0].componentType);
              }
            }}
          />
        );
      })}
      <div style={{ flexGrow: 1 }}></div>
      <div data-tour="challenge01-circuit-actions">
        <IconButton onClick={() => resetCircuit()}>
          <ReplayIcon />
        </IconButton>
      </div>
    </Toolbar>
  );
}

export function BlueprintGrid({ countCols, countRows, cells }: { countCols?: number; countRows?: number; cells?: CircuitComponent[][] }) {
  const classes = useStyles();
  const componentClasses = useComponentStyles(0);

  if (!cells) {
    return <Skeleton variant="rect" height={100} />;
  }

  return (
    <>
      {new Array(countRows).fill(1).map((_, i) => (
        <Grid key={i + 'bp'} container>
          {new Array(countCols).fill(1).map((_, j) => (
            <Grid
              key={i + '_' + j + 'bp'}
              item
              className={
                cells[i][j].componentType === 'disabled'
                  ? `${classes.blueprintGridCellDisabled}`
                  : `${componentClasses.componentBorder} ${classes.blueprintGridCell}`
              }
            ></Grid>
          ))}
        </Grid>
      ))}
    </>
  );
}
