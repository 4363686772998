import React from 'react';

import { makeStyles, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useComponentStyles } from '../theme/component.setup';
import { Markdown } from '../text/markdown';

const useStyles = makeStyles((theme) => ({
  challengeDescription: {
    marginLeft: '0.8em',
  },
  challengeObjectives: {
    marginLeft: '1.5em',
  },
}));

export function ChallengeDescription({ challengeId }: { challengeId: string }) {
  const [t] = useTranslation('challenges');
  const theme = useTheme();
  const classes = useStyles();
  const classesComponents = useComponentStyles(1);
  return (
    <div style={{ marginTop: theme.spacing(1), marginRight: theme.spacing(2) }}>
      <Typography component="div" variant="body1" paragraph className={classes.challengeDescription}>
        <Markdown>{t(`levels.${challengeId}.description`)}</Markdown>
      </Typography>

      <Typography component="div" variant="body1" className={`${classesComponents.highlightText} ${classes.challengeDescription}`}>
        {t('challenge.objectives')}:
      </Typography>
      <Typography component="div" variant="body1" className={`${classes.challengeDescription}`}>
        <Markdown>{t(`levels.${challengeId}.objectives`)}</Markdown>
      </Typography>
    </div>
  );
}
