import { CircuitComponent } from '../components/components';

function findTargetCells(cells: CircuitComponent[][], pos: { row: number; col: number }, item: { width: number; height: number }) {
  const targetRows = cells.filter((_, i) => i >= pos.row && i < pos.row + item.height);
  const targetCells: CircuitComponent[] = targetRows.flatMap((row) => row.filter((_, j) => j >= pos.col && j < pos.col + item.width));
  return targetCells;
}

function allCellsEmpty(targetCells: CircuitComponent[]): boolean {
  return targetCells.every((c) => c.componentType === 'empty');
}

function allCellsInGrid(targetCells: CircuitComponent[], item: { width: number; height: number }) {
  return targetCells.length === item.width * item.height;
}

export function isDropPossible(
  cells: CircuitComponent[][],
  pos: { row: number; col: number },
  item: { width: number; height: number }
): boolean {
  const targetCells: CircuitComponent[] = findTargetCells(cells, pos, item);

  return allCellsInGrid(targetCells, item) && allCellsEmpty(targetCells);
}
