import React, { useState, useEffect } from 'react';

import { useParams, Link } from 'react-router-dom';
import { Typography, useTheme, Paper, Divider, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PlayIcon from '@material-ui/icons/PlayArrow';

import { useChallengesRepository } from '../dependencies/challenges-repository-provider';
import { HeaderLayout } from '../theme/header-layout';
import { ChallengeDetail } from '../../model/challenges/challenge-repository';
import { SpawningChallengeTab, UrlRoutedChallengeTabs } from '../challenge-tabs/spawning-challenge-tabs';
import { ChallengeDescription } from './challenge-description';
import { ChallengeRunTable } from './challenge-run-table';
import { ChallengeMap } from './challenge-map';
import { ChallengeCircuit } from './challenge-circuit';
import { useChallengeStyles } from './challenge-theme';
import { CodeLibraryDrawer } from '../../library/code-library-drawer';
import { Messages } from '../../layout/snackbars';

const runtableTab = 'runtable';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      maxWidth: `calc(100vw - ${theme.spacing(2) * 2}px)`,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: `calc(100vw - ${theme.spacing(1) * 2}px)`,
    },
  }
}));

export function Challenge() {
  const classes = useStyles();
  const [tErrors] = useTranslation();
  const [t] = useTranslation('challenges');
  const challengesRepository = useChallengesRepository();
  const { challengeId } = useParams<{ challengeId: string }>();
  const [challenge, setChallenge] = useState<ChallengeDetail | undefined | 404>(undefined);

  useEffect(() => {
    async function loadChallenge() {
      if (!challengeId) return;
      const c = await challengesRepository.getChallenge(challengeId);
      setChallenge(c || 404);
    }
    loadChallenge();
  }, [challengeId, challengesRepository]);

  if (!challenge) {
    return <></>;
  } else if (challenge === 404) {
    return (
      <div className={classes.root}>
        <Typography variant="h3" component="h1" color="error">
          {tErrors('errors.challenge404')}
        </Typography>
        <Link to="/" style={{ color: 'white' }}>
          Home
          </Link>
      </div>
    );
  }

  return (
    <HeaderLayout>
      <Messages>
        <CodeLibraryDrawer >
          <div className={classes.root}>
            <Typography variant="h3" component="h1">
              {t(`levels.${challenge.id}.title`)}
            </Typography>
            <UrlRoutedChallengeTabs runTab={runtableTab}>
              {[
                <SpawningChallengeTab key={-1} id="informations" label={t('common.informations')}>
                  <Description challenge={challenge} />
                </SpawningChallengeTab>,

                <SpawningChallengeTab key={-2} id={runtableTab} label={t('common.execution')} icon={<PlayIcon />}>
                  <Runtable challenge={challenge} autoexec={false} />
                </SpawningChallengeTab>,
              ].concat(
                challenge.circuits.map((c) => (
                  <SpawningChallengeTab key={c.id} id={c.id} label={t(`common.circuit`) + ' - ' + t(`levels.${challengeId}.${c.id}`)}>
                    <ChallengeCircuit
                      challengeId={challenge.id}
                      initialCircuit={c}
                    />
                  </SpawningChallengeTab>
                ))
              )}
            </UrlRoutedChallengeTabs>
          </div>
        </CodeLibraryDrawer>
      </Messages>
    </HeaderLayout>
  );
}

function Description({ challenge }: { challenge?: ChallengeDetail }) {
  const challengeStyles = useChallengeStyles();
  return (
    <Paper variant="outlined" className={challengeStyles.tab} >
      {challenge && <ChallengeDescription challengeId={challenge.id} />}
    </Paper>
  );
}
function Runtable({ challenge, autoexec }: { challenge?: ChallengeDetail; autoexec: boolean }) {
  const theme = useTheme();
  const [t] = useTranslation('challenges');
  const challengeStyles = useChallengeStyles();
  const circuitId = challenge?.circuits[0]?.id;
  return (
    <>
      <Paper variant="outlined" className={challengeStyles.tab}>
        {challenge && circuitId && (
          <ChallengeRunTable
            runTable={challenge.runTable}
            challengeId={challenge.id}
            challengeName={t(`levels.${challenge.id}.title`)}
            circuitId={circuitId}
          />
        )}
        <Divider variant="middle" style={{ marginTop: theme.spacing(2) }} />
        {challenge && <ChallengeMap map={challenge.map} />}
      </Paper>
    </>
  );
}
