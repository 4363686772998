import React from 'react';

import { IconButton, Badge } from '@material-ui/core';

import { circuitComponentTypeCatalog } from './ui/component-view';
import { green } from '@material-ui/core/colors';
import { CircuitComponentType } from '../../model/components/components';

const iconSize = { unit: 'px', size: 24 };

export interface ToolbarThumbComponentProps {
  type: CircuitComponentType;
  max: number | null;
  placed: number | undefined;
  selected: boolean;
  onMouseDown: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export function ToolbarThumbComponent({ type, onMouseDown, selected, max, placed }: ToolbarThumbComponentProps) {
  const item = circuitComponentTypeCatalog[type];
  if (!item) return <></>;
  const img = item.thumb || item.img;
  if (!img) return <></>;
  const count = max ? max - (placed || 0) : max;
  return (
    <IconButton
      onMouseDown={(e) => {
        count !== 0 && onMouseDown(e);
      }}
      onMouseUp={(e) => onMouseDown(e)}
      disabled={count === 0}
    >
      <Badge badgeContent={count} invisible={max === null} color="primary">
        {img && (
          <div
            style={{
              width: `${item.width * iconSize.size}${iconSize.unit}`,
              height: `${item.height * iconSize.size}${iconSize.unit}`,
              color: selected ? green[200] : 'inherit',
            }}
          >
            {img}
          </div>
        )}
      </Badge>
    </IconButton>
  );
}
