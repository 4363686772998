import React, { useState, useEffect, useContext } from 'react';

import { Popover, Paper, Grid, IconButton, Icon, Select, MenuItem, Divider, useTheme } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import { useComponentStyles, fontFamily } from '../theme/component.setup';
import { ItemActions } from './ui/component-view';
import { LoadableFunction } from '../../model/code/function';
import { UserContext } from '../user/user-provider';
import { DatabaseFactory } from '../../model/database/database';

export type PopoverDefinition = {
  row: number;
  col: number;
  actions?: ItemActions[];
  el?: Element;
};

export function ComponentPopover({
  selectedFunction,
  updateSelectedFunction,
  popover,
  onClose,
  onDelete,
}: {
  popover: PopoverDefinition | undefined;
  onClose: () => void;
  onDelete: (row: number, col: number) => void;
  selectedFunction: string;
  updateSelectedFunction: (s: string) => void;
}) {
  const classesComponent = useComponentStyles(0);

  if (!popover?.el || !popover?.actions?.length) {
    return <></>;
  }
  const element = popover.el;
  const remove = () => {
    onDelete(popover.row, popover.col);
    onClose();
  };

  return (
    <Popover
      open={true}
      anchorEl={element}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <Paper className={classesComponent.componentBorder} variant="outlined" elevation={6} style={{ width: '100%' }}>
        <Grid container>
          {popover.actions &&
            popover.actions.map((action, i) => {
              if (action === 'delete') {
                return (
                  <Action key={i}>
                    <DeleteAction remove={remove} />
                  </Action>
                );
              } else if (action === 'load-function') {
                return (
                  <Action key={i}>
                    <LoadFunctionAction selectedFunction={selectedFunction} updateSelectedFunction={updateSelectedFunction} />
                  </Action>
                );
              }
              return <></>;
            })}
        </Grid>
      </Paper>
    </Popover>
  );
}

function Action({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Grid item>{children}</Grid>
      <Divider orientation="vertical" flexItem />
    </>
  );
}

function DeleteAction({ remove }: { remove: () => void }) {
  return (
    <IconButton onClick={remove}>
      <Icon>delete</Icon>
    </IconButton>
  );
}

function LoadFunctionAction({
  selectedFunction,
  updateSelectedFunction,
}: {
  selectedFunction: string;
  updateSelectedFunction: (s: string) => void;
}) {
  const currentUser = useContext(UserContext);

  const [functions, setFunctions] = useState<LoadableFunction[]>([]);
  useEffect(() => {
    async function loadFunctions() {
      const database = DatabaseFactory(currentUser.user);
      const result = await database.loadFunctions();
      setFunctions(result);
    }
    loadFunctions();
  }, [currentUser.user]);
  const theme = useTheme();

  return (
    <Grid container alignContent="stretch" style={{ marginRight: theme.spacing(1) }}>
      <Grid item style={{ verticalAlign: 'middle' }}>
        <SaveAltIcon style={{ marginRight: '3px', height: '100%' }} />
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <Select
          value={selectedFunction}
          variant="outlined"
          style={{ width: '90%' }}
          onChange={(e) => updateSelectedFunction(e.target.value as string)}
        >
          <MenuItem value={'-1'}>
            <em>Select a function</em>
          </MenuItem>
          {functions.map((f) => (
            <MenuItem key={f.id} value={f.id} style={{ fontFamily: fontFamily }}>
              {f.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}
