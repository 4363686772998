import React from 'react';
import { circuitComponentTypeCatalog } from './ui/component-view';
import { makeStyles } from '@material-ui/core';
import { colSize, rowSize } from './circuit-component';
import { CircuitComponentType } from '../../model/components/components';

const useStyles = makeStyles({
  droppable: {
    backgroundColor: 'rgba(20,20,20,0.4)',
  },
  undroppable: {
    backgroundColor: 'rgba(220,0,0,0.4)',
  },
});

export function CircuitShadowComponent({ type, dropPossible }: { type: CircuitComponentType; dropPossible: boolean }) {
  const classes = useStyles();
  const comp = circuitComponentTypeCatalog[type];

  if (!comp) return <></>;

  return (
    <div
      className={dropPossible ? classes.droppable : classes.undroppable}
      style={{
        position: 'absolute',
        top: 0,
        width: `${comp.width * colSize.size}${colSize.unit}`,
        height: `${comp.height * rowSize.size}${rowSize.unit}`,
      }}
    ></div>
  );
}
