import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';

export function DialogAbout({ open, onClose }: { open: boolean; onClose: () => void }) {
  const content = `
    **Algopolis** - version _Alpha_ © 2020
    
    City Tiles by [Kenney Vleugels](https://www.kenney.nl/)
    `;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>About</DialogTitle>
      <DialogContent>
        <ReactMarkdown>{content}</ReactMarkdown>
      </DialogContent>
    </Dialog>
  );
}
