export class CompilableAssemblyCode {
  static parse(separator: string, parsable: string): CompilableAssemblyCode {
    return new CompilableAssemblyCode(parsable.split(separator));
  }

  constructor(private readonly code: string[]) {}

  length(): number {
    return this.code.length;
  }

  join(separator: string): string {
    return this.code.join(separator);
  }

  map<T>(f: (s: string, idx: number) => T): T[] {
    return this.code.map(f);
  }

  append(lineNumber: number, append: string): CompilableAssemblyCode {
    return new CompilableAssemblyCode(
      this.code.map((line, index) => {
        if (index !== lineNumber) {
          return line;
        }
        return line + append;
      })
    );
  }
}
