import { Uint8 } from '../integer/uint8';

export class TestCaseOneInputOneOutput {
  constructor(readonly id: string, readonly name: string, readonly input: Uint8[][], readonly acc: Uint8, readonly expected: Uint8[]) {
    if (!this.input.length) {
      this.input.push([new Uint8(0)]);
    }
  }

  setInput(newInput: Uint8[][]): TestCaseOneInputOneOutput {
    return new TestCaseOneInputOneOutput(this.id, this.name, newInput, this.acc, this.expected);
  }
  setAcc(newAcc: Uint8): TestCaseOneInputOneOutput {
    return new TestCaseOneInputOneOutput(this.id, this.name, this.input, newAcc, this.expected);
  }
  setExpected(newExpected: Uint8[]): TestCaseOneInputOneOutput {
    return new TestCaseOneInputOneOutput(this.id, this.name, this.input, this.acc, newExpected);
  }
  setName(newName: string): TestCaseOneInputOneOutput {
    return new TestCaseOneInputOneOutput(this.id, newName, this.input, this.acc, this.expected);
  }
}
