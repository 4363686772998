export class HashParams {
  constructor(private readonly params?: Record<string, string>) { }

  toQuery(): string {
    if (!this.params) return '';
    const params = this.params;
    const query = Object.keys(params)
      .map((param) => `${param}=${params[param]}`)
      .join('&');
    if (query.length === 0) return '';
    return '#' + query;
  }
}
