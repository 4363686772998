import { IconProp } from '@fortawesome/fontawesome-svg-core';
import axios from 'axios';

import { CircuitComponentType, CircuitComponent } from '../../model/components/components';
import { generateComponentId } from '../generateid';
// import { Challenge } from '../../components/challenge/challenge';

// Challenge template
// {
//   id: 'demo02',
//   title: 'levels.tutorial02.title',
//   subtitle: 'levels.tutorial02.subtitle',
//   icon: 'settings_brightness',
//   description: [],
//   objectives: [],
//   map: [],
//   runTable: {
//     inputIcon: { iconProp: faCloudSun },
//     resultIcons: [],
//     input: [],
//   },
//   circuits: [],
// },

// function demo01Blueprint() {
//   const cells = new Array<CircuitComponentType2[]>(3).fill([]).map((_) => new Array<CircuitComponentType2>(8).fill('empty'));
//   cells[0][0] = 'disabled';
//   cells[0][1] = 'disabled';
//   cells[0][6] = 'disabled';
//   cells[0][7] = 'disabled';
//   cells[1][0] = 'disabled';
//   cells[1][1] = 'disabled';
//   cells[1][6] = 'disabled';
//   cells[1][7] = 'disabled';
//   cells[2][0] = 'temp';
//   cells[2][7] = 'frost';
//   return cells;
// }

export class Blueprint {
  constructor(readonly height: number, readonly width: number, readonly cells: CircuitComponent[][] = []) {
    if (cells.length !== height) {
      this.cells = new Array<CircuitComponent[]>(height)
        .fill([])
        .map((_) => new Array<CircuitComponent>(width).fill({ componentType: 'empty' }));
    }
  }
  cell(row: number, col: number, componentType: CircuitComponentType, onModify: () => void) {
    if (this.cells[row][col].componentType === componentType) {
      return this;
    }
    const newCells = this.cells.map((row) => row.concat([]));
    newCells[row][col] = {
      componentType,
      id: generateComponentId(componentType),
    };
    onModify();
    return new Blueprint(this.height, this.width, newCells);
  }
  replaceCell(row: number, col: number, comp: CircuitComponent) {
    const newCells = this.cells.map((row) => row.concat([]));
    newCells[row][col] = comp;
    return new Blueprint(this.height, this.width, newCells);
  }
  countTypes(): Map<CircuitComponentType, number> {
    const result = new Map<CircuitComponentType, number>();
    this.cells.forEach((row) => {
      row.forEach((cell) => {
        const oldCount = result.get(cell.componentType) || 0;
        result.set(cell.componentType, oldCount + 1);
      });
    });
    return result;
  }
}

export type IconDto = {
  component?: CircuitComponentType;
  fontawesome?: string;
  material?: string;
};

export type ChallengeOverview = {
  id: string;
  icon: IconDto;
};

export type ChallengeCategory = {
  id: string;
  challenges: ChallengeOverview[];
};

export type ChallengeTableOfContents = {
  categories: ChallengeCategory[];
};

export type ClickableTile = {
  icon?: IconProp;
  component: CircuitComponent;
  circuit: string;
};

export type Tile = {
  filename: string;
  width: number;
  height: number;
  clickable: false | ClickableTile;
};
export type ThemeColor = 'primary' | 'low' | 'high' | 'tron';

export type RunInput = {
  value: string | IconProp;
  color: ThemeColor;
};
export type RunInputLine = {
  icon?: RunResultPossibility;
  values: RunInput[];
};
export type RunOutputLine = {
  id: string;
  icon?: RunResultPossibility;
};

export type RunResultPossibility = {
  iconDto: IconDto;
  themeColor?: ThemeColor;
};

export type RunTableData = {
  input: RunInputLine[];
  output: RunOutputLine[];
};

export type ChallengeCircuitDefinition = {
  id: string;
  allowedComponentTypes: [CircuitComponent, number | null][];
  blueprint: Blueprint;
};

export type ChallengeDetail = ChallengeOverview & {
  map: Tile[][];
  runTable: RunTableData;
  circuits: ChallengeCircuitDefinition[];
};

export class ChallengesRepository {
  async getChallengeList(): Promise<ChallengeTableOfContents> {
    const list = await axios.get<ChallengeTableOfContents>('/api/challenges/overview');
    return list.data;
  }

  async getChallenge(challengeId: string): Promise<ChallengeDetail | undefined> {
    const challenge = await axios.get<ChallengeDetail>(`/api/${challengeId}/detail`);
    if (!challenge || !challenge.data) {
      return undefined;
    }
    challenge.data.circuits = challenge.data.circuits.map((c) => {
      c.blueprint = new Blueprint(c.blueprint.height, c.blueprint.width, c.blueprint.cells);
      return c;
    });
    return challenge.data;
  }
}
