import React from 'react';

import { circuitComponentTypeCatalog } from './ui/component-view';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { CircuitComponentType } from '../../model/components/components';

type MapIconProps = {
  type: CircuitComponentType;
  style: CSSProperties;
};

export function MapIcon({ type, style }: MapIconProps) {
  const item = circuitComponentTypeCatalog[type];
  const img = item?.mapImg;
  if (!img) return <></>;
  return <div style={style}>{img}</div>;
}
