import React, { useState, useEffect, useCallback, useContext } from 'react';

import axios from 'axios';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  SvgIcon,
  IconButton,
  Icon,
  Box,
  makeStyles,
  Dialog,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import PlayIcon from '@material-ui/icons/PlayArrow';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { green, red } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { RunTableData, RunResultPossibility, RunInput, RunOutputLine } from '../../model/challenges/challenge-repository';
import { ExecutionErrors } from '../../model/errors/errors-from-server';
import { IconChallenge } from '../icons/challenge-icon';
import { useComponentStyles } from '../theme/component.setup';
import { Markdown } from '../text/markdown';
import { UserContext } from '../user/user-provider';
import { DatabaseFactory } from '../../model/database/database';
import { DialogEndTutorial } from '../login/dialog-endtutorial';
import { useMessages } from '../../layout/snackbars';

// type Errors = 'run.serverUnreachable' | 'run.circuitNotCompleted' | 'run.compileErrors' | ExecutionErrors;

// function SlideTransition(props: TransitionProps) {
//   return <Slide {...props} direction="up" />;
// }

type OutputValuesDto = { [k: string]: { values: number[]; checked: boolean[] } };

type RunResultDto = {
  mapValues: OutputValuesDto;
  executionError?: ExecutionErrors;
  runResult: boolean;
  compileErrors?: number[];
};

const useStyles = makeStyles((theme) => ({
  iconCell: {
    paddingRight: theme.spacing(3),
  },
  primary: {
    color: theme.palette.common.white,
  },
  low: {
    color: red[600],
  },
  high: {
    color: green[600],
  },
  tron: {},
}));

export function ChallengeRunTable({
  challengeId,
  challengeName,
  runTable,
  circuitId,
}: {
  challengeId: string;
  challengeName: string;
  circuitId: string;
  runTable: RunTableData;
}) {
  const messages = useMessages();
  const { hash } = useLocation();
  const [autoRun, setAutorun] = useState(false);
  const [t] = useTranslation();
  const [mapValues, setMapValues] = useState<OutputValuesDto | undefined>(undefined);
  // const [alertError, setAlertError] = useState<Errors | undefined>(undefined);
  const [runSuccess, setRunSuccess] = useState<boolean | undefined>(undefined);
  const [endTutorial, setEndTutorial] = useState<boolean>(false);
  const currentUser = useContext(UserContext);

  const classes = useStyles();

  const handleRun = useCallback(async () => {
    const database = DatabaseFactory(currentUser.user);
    const circuit = await database.loadCircuitForChallenge(challengeId, circuitId);
    const functions = await database.loadFunctions();
    const data = {
      circuits: [
        {
          id: circuit.circuitId,
          circuit: circuit.blueprint.cells.map((row) =>
            row.map((c) => {
              if (c.componentType === 'ape01') {
                const functionId = c.functionId;
                const func = functions.find((f) => f.id === functionId);
                return {
                  ...c,
                  code: func?.code.map((s) => s),
                };
              }
              return c;
            })
          ),
        },
      ],
    };
    try {
      const result = await axios.post<RunResultDto>(`/api/${challengeId}/run`, data);
      if (result.data.mapValues && Object.keys(result.data.mapValues).length) {
        setMapValues(result.data.mapValues);
      } else {
        setMapValues(undefined);
      }
      if (result.data.compileErrors?.length) {
        // setAlertError('run.compileErrors');
        messages.display({ severity: 'error', message: t('run.compileErrors') });
        return;
      } else if (result.data.executionError) {
        messages.display({ severity: 'error', message: t(result.data.executionError) });
        // setAlertError(result.data.executionError);
      }
      const success = result.data.runResult;
      await database.setStatus(challengeId, success);
      setRunSuccess(result.data.executionError ? undefined : success);
      if (!result.data.executionError && !success) {
        messages.display({ severity: 'error', message: t(`run.fail`) })
      }
      if (success && (await database.displaySubscribeMessage())) {
        setEndTutorial(true);
      }
    } catch (e) {
      console.error(e);
      messages.display({ severity: 'error', message: t('run.serverUnreachable') });
      // setAlertError('run.serverUnreachable');
    }
  }, [challengeId, circuitId, messages, t, currentUser.user]);

  useEffect(() => {
    if (hash.indexOf('execute=true') >= 0) {
      console.log("RUUUUUN");
      setAutorun(true);
    }
  }, [hash]);
  useEffect(() => {
    if (autoRun) {
      handleRun();
      setAutorun(false);
    }
  }, [autoRun, handleRun]);

  return (
    <>
      <TableContainer data-tour="challenge-runtable">
        <Table>
          <TableBody>
            {runTable.input.map((input, idx) => (
              <TableRow key={idx}>
                <TableCell></TableCell>
                <TableCell className={classes.iconCell}>
                  <RunTableIcon icon={input.icon} />
                </TableCell>
                <RunTableInputCells inputArray={input.values} />
              </TableRow>
            ))}

            <RunTableOutputLine config={runTable.output} length={runTable.input[0].values.length} values={mapValues} onRun={handleRun} />

          </TableBody>
        </Table>
      </TableContainer>

      <SuccessDialog challengeName={challengeName} onClose={() => setRunSuccess(undefined)} runSuccess={runSuccess} />
      <DialogEndTutorial open={endTutorial} onClose={() => setEndTutorial(false)} />
    </>
  );
}

function RunTableIcon({ icon }: { icon?: RunResultPossibility }) {
  if (!icon) return <></>;
  return <IconChallenge icon={icon.iconDto} color={icon.themeColor} />;
}

function RunTableInputCells({ inputArray }: { inputArray: RunInput[] }) {
  const classes = useStyles();
  return (
    <>
      {inputArray.map((input, i) => (
        <TableCell key={`${i}-temp`}>
          <span className={classes[input.color]}>
            {typeof input.value === 'string' ? (
              <Markdown>{input.value}</Markdown>
            ) : (
                <SvgIcon>
                  <FontAwesomeIcon icon={input.value as IconProp} />
                </SvgIcon>
              )}
          </span>
        </TableCell>
      ))}
    </>
  );
}

function SuccessDialog({ runSuccess, challengeName, onClose }: { runSuccess?: boolean; challengeName: string; onClose: () => void }) {
  const history = useHistory();
  const classesComponents = useComponentStyles(0);
  const [t] = useTranslation();
  return (
    <Dialog
      PaperProps={{ style: { width: '100%' } }}
      open={runSuccess === true}
      onClose={onClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      maxWidth="sm"
    >
      <DialogTitle className={classesComponents.testSuccess}>{t('run.success')}</DialogTitle>
      <DialogContent>
        <Markdown>{t('run.succeed', { challengeName })}</Markdown>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {t('run.keep-editing')}
        </Button>
        <Button onClick={() => history.push('/')} color="primary">
          {t('run.back-challenges')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function RunTableOutputLine({ config, length, values, onRun }: { config: RunOutputLine[]; length: number; values?: OutputValuesDto; onRun: () => void }) {
  const classes = useStyles();
  return <>
    {
      config.map((line, idx) =>
        <TableRow key={idx}>
          <TableCell style={{ width: '48px' }}>
            {idx === 0 ? <IconButton onClick={onRun}>
              <PlayIcon />
            </IconButton> : ""}
          </TableCell>
          <TableCell key={idx} className={classes.iconCell}>
            <RunTableIcon icon={line.icon} />
          </TableCell>
          {
            values ? <OutputValues length={length} values={values[line.id].values} /> : <OutputQuestionMarks length={length} />
          }
        </TableRow>)
    }{
      config.map((line, idx) => <TableRow key={idx}>
        <TableCell></TableCell>
        <TableCell></TableCell>
        {
          new Array(length).fill(0).map((_, checkedIdx) => <TableCell key={`${checkedIdx}-signal-actual`}>{values ?
            <OuputValueCheckedIcon checked={values[line.id].checked[checkedIdx]} />
            : ""}</TableCell>)
        }
      </TableRow>
      )
    }


  </>;
}

function OuputValueCheckedIcon({ checked }: { checked: boolean }) {
  return checked ? <Icon fontSize="small" style={{ color: green[600] }}>
    check
            </Icon> : <Icon fontSize="small" style={{ color: red[600] }}>
      close
                </Icon>;
}

function OutputQuestionMarks({ length }: { length: number }) {
  return <>
    {
      new Array(length).fill(0).map((_, idx) => <TableCell key={`${idx}-signal-actual`}><SvgIcon fontSize="small" color="disabled">
        <FontAwesomeIcon icon={faQuestion} />
      </SvgIcon></TableCell>)
    }
  </>;
}
function OutputValues({ values, length }: { length: number; values: number[] }) {
  return <>
    {
      new Array(length).fill(0).map((_, idx) => <TableCell key={`${idx}-signal-actual`}><Box>{values[idx]}</Box></TableCell>)
    }
  </>;
}