import axios from 'axios';

import { Uint8 } from '../integer/uint8';
import { Dialect, LoadableFunction } from '../code/function';
import { CompilableAssemblyCode } from '../code/compilable-assembly-code/compilable-assembly-code';
import { TourActiveSteps } from '../tour/tour-active-steps';
import { Blueprint, ChallengesRepository } from '../challenges/challenge-repository';
import { TestCaseOneInputOneOutput } from '../test/test-case';
import { Database, ChallengeCircuitCustomized } from './database';
import { User } from '../user/user';
import { CircuitComponent } from '../components/components';

type BluePrintDto = { width: number; height: number; cells: CircuitComponent[][] };

const keyTourActiveSteps = 'touractivesteps';

// const storeFunctions: LoadableFunction[] | undefined = undefined;
// const storeFunctionsRunning = false;

enum Collection {
  Functions = 'functions',
  Circuit = 'circuit',
  Status = 'status',
}

type StatusInDto = {
  status: boolean | undefined;
};

type FunctionInDto = {
  id: string;
  label?: string;
  code?: string[];
  dialect: Dialect;
  testCases?: { id: string; name: string; acc: number; expected: number[]; input: number[][] }[];
};

export class HybridStorageDatabase implements Database {
  constructor(readonly user: User) { }

  async loadFunctions(): Promise<LoadableFunction[]> {
    const response = await axios.get<{ functions: FunctionInDto[] }>(`/api/function`);
    return response.data.functions.map(
      (f) =>
        new LoadableFunction(
          f.id,
          f.label || '',
          new CompilableAssemblyCode(f.code || []),
          f.testCases
            ? f.testCases.map(
              (t) =>
                new TestCaseOneInputOneOutput(
                  t.id,
                  t.name,
                  t.input.map((arr) => arr.map(i => new Uint8(i))),
                  new Uint8(t.acc),
                  t.expected.map(exp => new Uint8(exp))
                )
            )
            : [],
          f.dialect,
        )
    ).sort((f1, f2) => f1.label.localeCompare(f2.label));
  }

  async storeFunctions(functions: LoadableFunction[]) {
    await axios.put(`/api/function`, {
      functions: functions.map((f) => ({
        id: f.id,
        label: f.label,
        code: f.code.map((l) => l),
        dialect: f.dialect,
        testCases: f.testCases.map((t) => ({
          id: t.id,
          name: t.name,
          acc: t.acc.toNumber(),
          expected: t.expected.map(exp => exp.toNumber()),
          input: t.input.map(arr => arr.map((n) => n.toNumber())),
        })),
      })),
    });
  }

  async loadCircuitForChallenge(challengeId: string, circuitId: string): Promise<ChallengeCircuitCustomized> {
    const response = await axios.get<BluePrintDto>(
      `/api/challenge/${challengeId}/customcircuit/${circuitId}`
    );
    const result = response.data;
    return {
      challengeId,
      circuitId,
      blueprint: new Blueprint(result.height, result.width, result.cells),
    };
  }

  async storeChallengeCircuit(circuit: ChallengeCircuitCustomized) {
    await axios.put(`/api/challenge/${circuit.challengeId}/customcircuit/${circuit.circuitId}`, {
      blueprint: circuit.blueprint.cells,
      reset: false,
    });
  }
  async resetCircuit(circuit: ChallengeCircuitCustomized) {
    const response = await axios.put<{ Reset: BluePrintDto }>(`/api/challenge/${circuit.challengeId}/customcircuit/${circuit.circuitId}`, {
      reset: true,
    });
    const result = response.data.Reset;
    return {
      challengeId: circuit.challengeId,
      circuitId: circuit.circuitId,
      blueprint: new Blueprint(result.height, result.width, result.cells),
    };
  }

  async getStatus(challengeId: string): Promise<boolean> {
    const response = await axios.get<StatusInDto>(`/api/challenge/${challengeId}/status`);
    return response.data.status === true;
  }

  async setStatus(challengeId: string, b: boolean) {
    await axios.put(`/api/challenge/${challengeId}/status`, {
      status: b,
    });
  }

  private keyCircuit(challengeId: string, circuitId: string, collection: Collection): string {
    return `${challengeId}-${circuitId}-${collection}`;
  }

  loadTourActiveSteps(): TourActiveSteps {
    const result: TourActiveSteps = {
      levels: false,
      challenge: false,
      circuit: false,
      functions: false,
      challengeInformations: true,
      challengeCircuit: true,
      challengeExecution: true,
      ide: true,
    };
    const json = localStorage.getItem(keyTourActiveSteps);
    if (json) {
      return JSON.parse(json) as TourActiveSteps;
    }
    this.setTourActiveSteps(result);
    return result;
  }

  setTourActiveSteps(touractivesteps: TourActiveSteps) {
    localStorage.setItem(keyTourActiveSteps, JSON.stringify(touractivesteps));
  }

  async displaySubscribeMessage(): Promise<boolean> {
    const challenges = await new ChallengesRepository().getChallengeList();
    const promises = challenges.categories
      .find((cat) => cat.id === 'MZ5qaI-UM')
      ?.challenges.map((challenge) => this.getStatus(challenge.id));
    if (!promises) {
      return false;
    }
    const result = await Promise.all(promises);
    return result.every((b) => b === true);
  }
}
