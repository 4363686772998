import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core';

import { componentBorderColor } from '../theme/component.setup';
import { Tile } from '../../model/challenges/challenge-repository';
import { SpawningChallengeTabsContext } from '../challenge-tabs/spawning-challenge-tabs';
import { MapIcon } from '../circuit/map-icon';
import { teal } from '@material-ui/core/colors';

const side = 133;

const useStyles = makeStyles({
  tile: {
    display: 'inline-block',
    position: 'relative',
  },
  image: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: componentBorderColor,
      opacity: 0.8,
    },
  },
});

export function ChallengeMap({ map }: { map: Tile[][] }) {
  return (
    <div
      style={{
        width: `${(side * 3) / 2}px`,
        height: `${side + (side * 2) / 3}px`,
        marginTop: 35,
        marginLeft: (side / 2) * 3,
      }}
    >
      <Map map={map} />
    </div>
  );
}

function Map({ map }: { map: Tile[][] }) {
  const { open } = useContext(SpawningChallengeTabsContext);
  const classes = useStyles();
  const border = 0;
  return (
    <>
      {map.map((row, rowi) => {
        return (
          <div key={`${rowi}`} style={{ height: 100, display: 'flex', border: `${border}px solid green` }} data-tour="challenge-map">
            {row.map((cell, celli) => {
              let top = rowi * -65 - (cell.height - 100);
              top += 33 * celli;

              let left = rowi * (cell.width / 2) * -1;
              left += (cell.width / 2) * celli * -1;
              return (
                <div
                  key={`${rowi}-${celli}`}
                  style={{
                    top,
                    left,
                    width: cell.width,
                    height: cell.height,
                    border: `${border}px solid red`,
                    position: 'relative',
                  }}
                >
                  <img src={cell.filename} alt="tile" />
                  {cell.clickable && (
                    <MapIcon
                      type={cell.clickable.component.componentType}
                      style={{
                        width: `${cell.width / 2}px`,
                        height: `${cell.height / 2}px`,
                        top: -cell.height,
                        left: cell.width / 4,
                        position: 'relative',
                        lineHeight: `${cell.height / 2}px`,
                        color: teal[600],
                      }}
                    />
                  )}
                  {cell.clickable && (
                    <div
                      style={{
                        height: '34px',
                        width: '68px',
                        opacity: 0.3,
                        transform: 'skew(-63deg, 24deg)',
                        position: 'relative',
                        top: '-138px',
                        left: '30px',
                        zIndex: 3,
                      }}
                      className={classes.image}
                      onClick={() => cell.clickable && open(cell.clickable.circuit)}
                    ></div>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
}
