import { useState } from 'react';
import { User } from '../../model/user/user';
import { Database, DatabaseFactory } from '../../model/database/database';


export function useDatabase(user: User) {
  const [database] = useState<Database>(DatabaseFactory(user));

  return database;
}
