import React, { useState, useContext } from 'react';

import { useTheme, Dialog, DialogContent, Container, Typography, TextField, Button } from '@material-ui/core';
import { UserContext } from '../user/user-provider';

const debug = true;

export function DialogEndTutorial({ open, onClose }: { open: boolean; onClose: () => void }) {
  if (debug) {
    return <Auth0Login open={open} onClose={onClose} />;
  }
  return <MailChimp open={open} onClose={onClose} />;
}

function MailChimp({ open, onClose }: { open: boolean; onClose: () => void }) {
  const theme = useTheme();
  const [email, setEmail] = useState('');
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Container maxWidth="lg" style={{ textAlign: 'center', marginTop: theme.spacing(5), marginBottom: theme.spacing(4) }}>
          <div id="mc_embed_signup">
            <form
              action="https://algopolis.us18.list-manage.com/subscribe/post?u=130377748ff91da92049209e0&amp;id=870fc44ace"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
              onSubmit={() => {
                onClose();
                return true;
              }}
            >
              <div id="mc_embed_signup_scroll">
                <Typography variant="h2" style={{ marginBottom: '32px' }}>
                  Congratulations!
                </Typography>
                <Typography variant="body1">You have achieved all challenges in tutorial... But this is only a beginning</Typography>
                <Typography variant="subtitle1" style={{}}>
                  Don't miss new challenges
                </Typography>
                <Typography variant="body1">Submit to newsletter to keep aware of updates</Typography>
                <div className="mc-field-group" style={{ marginBottom: '32px', marginTop: '24px' }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="email"
                    value={email}
                    name="EMAIL"
                    className="required email"
                    id="mce-EMAIL"
                    label="Email address"
                    onChange={(e) => setEmail(e.currentTarget.value)}
                  />
                </div>
                <div className="clear" style={{ display: 'inline', marginLeft: '12px' }}>
                  <Button
                    variant="contained"
                    type="submit"
                    value="Subscribe"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="button"
                  >
                    Subscribe
                  </Button>
                </div>
                <div id="mce-responses" className="clear">
                  <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                  <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                </div>
                <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                  <input type="text" name="b_130377748ff91da92049209e0_870fc44ace" value="" readOnly />
                </div>
              </div>
            </form>
          </div>
        </Container>
      </DialogContent>
    </Dialog>
  );
}

function Auth0Login({ open, onClose }: { open: boolean; onClose: () => void }) {
  const theme = useTheme();
  const { user, login } = useContext(UserContext);

  return (
    <Dialog open={open && !user.isAuthenticated} onClose={onClose}>
      <DialogContent>
        <Container maxWidth="lg" style={{ textAlign: 'center', marginTop: theme.spacing(5), marginBottom: theme.spacing(4) }}>
          <div id="mc_embed_signup">
            <div id="mc_embed_signup_scroll">
              <Typography variant="h2" style={{ marginBottom: '32px' }}>
                Congratulations!
              </Typography>
              <Typography variant="body1">You have achieved all challenges in tutorial... But this is only a beginning</Typography>
              <Typography variant="subtitle1" style={{}}>
                Don't miss next updates
              </Typography>
              <Button variant="contained" onClick={() => login()}>
                Create your free account
              </Button>
            </div>
          </div>
        </Container>
      </DialogContent>
    </Dialog>
  );
}
