import React, { useReducer, useState, useEffect } from 'react';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Drawer, useTheme, makeStyles, Box, Grid, SvgIcon, List, ListItem, ListItemText, ListItemIcon, Divider, Hidden, IconButton } from '@material-ui/core';
import { faFileCode, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LoadableFunction } from '../model/code/function';
import { useCurrentUser } from '../components/user/user-provider';
import { User } from '../model/user/user';
import { DatabaseFactory } from '../model/database/database';
import { ToolBarSpace } from '../components/header/header';
import { useDatabase } from '../components/dependencies/use-database';
import { CompilableAssemblyCode } from '../model/code/compilable-assembly-code/compilable-assembly-code';
import { generateFunctionId } from '../model/generateid';
import { CodeEditor, Action, OpenFunction } from './code-editor';


const useFunctions = (user: User) => {
  const functionsReducer = (state: LoadableFunction[], action: Action): LoadableFunction[] => {
    switch (action.type) {
      case 'add': {
        const newState = [...state, action.add];
        return newState;
      }
      case 'replace': {
        const newFcts = state.slice(0, action.idx).concat(action.add, state.slice(action.idx + 1));
        return newFcts;
      }
      case 'delete': {
        const newFcts = state.slice(0, action.idx).concat(state.slice(action.idx + 1));
        return newFcts;
      }
      case 'init': {
        const newFcts = action.init || [];

        return newFcts;
      }
      default:
        return state;
    }
  };

  return useReducer(functionsReducer, []);
};


const switchingDrawer = '95vw';
const permanentDrawerSize = 45;
const permanentDrawer = `${permanentDrawerSize}vw`;
const challengeSpace = `${100 - permanentDrawerSize}vw`;

const drawerStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: switchingDrawer
    },
    [theme.breakpoints.up('sm')]: {
      width: permanentDrawer
    }
  },
  drawerPaper: {
    [theme.breakpoints.down('sm')]: {
      width: switchingDrawer
    },
    [theme.breakpoints.up('sm')]: {
      width: permanentDrawer
    }
  },
  challenge: {
    [theme.breakpoints.up('md')]: {
      marginLeft: permanentDrawer,
      width: challengeSpace,
      maxWidth: challengeSpace,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '31px',
    },
  },
  editor: {
    flexBasis: `calc(75% - 1px - ${theme.spacing(0.7)}px)`,
    overflowY: 'scroll',
    overflowX: 'hidden',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.7)
  },
  closer: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
}));

export type CodeLibraryDrawerProps = {
  children: React.ReactNode;
};

export function CodeLibraryDrawer(props: CodeLibraryDrawerProps) {
  const classes = drawerStyles();
  const [open, setOpen] = useState(false);

  return <>
    <Hidden smDown>
      <Drawer variant="permanent" className={classes.root} PaperProps={{ className: classes.drawerPaper }}>
        <ToolBarSpace />
        <CodeLibraryInnerDrawer />
      </Drawer >
    </Hidden>
    <Hidden mdUp>
      <Drawer open={!open} variant="persistent">
        <ToolBarSpace />
        <IconButton size="small" onClick={() => setOpen(true)}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Drawer>
      <Drawer open={open} className={classes.root} PaperProps={{ className: classes.drawerPaper }}>
        <div className={classes.closer}>
          <IconButton onClick={() => setOpen(false)}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <CodeLibraryInnerDrawer />
      </Drawer >
    </Hidden>
    <Box className={classes.challenge}>
      {props.children}
    </Box>
  </>
}

const delay = 600;
let fTimeout: NodeJS.Timeout | undefined = undefined;
let loading = true;

function CodeLibraryInnerDrawer() {
  const theme = useTheme();
  const classes = drawerStyles();
  const currentUser = useCurrentUser();
  const [functions, dispatchFunctions] = useFunctions(currentUser.user);
  const [openFunctionId, setOpenFunctionId] = useState<OpenFunction | undefined>(undefined);
  const database = useDatabase(currentUser.user);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (fTimeout) {
      clearTimeout(fTimeout);
    }
    fTimeout = setTimeout(async () => {
      await database.storeFunctions(functions);
    }, delay);
  }, [functions, database]);

  useEffect(() => {
    async function loadFunctions() {
      loading = true;
      const database = DatabaseFactory(currentUser.user);
      const result = await database.loadFunctions();
      dispatchFunctions({ type: 'init', init: result });
      loading = false;
      return result;
    }
    loadFunctions();
  }, [dispatchFunctions, currentUser.user]);

  return <Grid container direction="column" style={{ marginTop: theme.spacing(2), height: '100%', maxHeight: '100%', overflow: 'hidden' }}>
    <Grid item style={{ flexBasis: '25%', overflow: 'hidden', }}>
      <FunctionsDisplay
        functions={functions}
        onSelect={functionId => setOpenFunctionId({ functionId, focus: 'code' })}
        onCreateNewFunction={async () => {
          const newFuncId = generateFunctionId();
          const fct = new LoadableFunction(newFuncId, 'New Function', new CompilableAssemblyCode(['hold', 'commit', 'jump 0']), []);
          dispatchFunctions({ type: 'add', add: fct, idx: -1 });
          setOpenFunctionId({ functionId: newFuncId, focus: 'functionName' });
        }} />
    </Grid>
    <Divider />
    <Grid item className={classes.editor}>
      <CodeEditor
        openFunctionId={openFunctionId?.functionId}
        focus={openFunctionId?.focus}
        functions={functions}
        dispatchFunctions={dispatchFunctions}
      />
    </Grid>
  </Grid>
}

function FunctionsDisplay({ functions, onSelect, onCreateNewFunction }: { functions: LoadableFunction[]; onSelect: (funcId: string) => void; onCreateNewFunction: () => void }) {
  return <>
    <List style={{ maxHeight: '48px', height: '48px', margin: 0, padding: 0 }}>
      <ListItem button onClick={onCreateNewFunction}>
        <ListItemIcon>
          <SvgIcon><FontAwesomeIcon icon={faPlusCircle} /></SvgIcon>
        </ListItemIcon>
        <ListItemText primary="New Function" />
      </ListItem>
      <Divider />
    </List>
    <List style={{ height: 'calc(100% - 49px)', overflowY: 'scroll', padding: 0, margin: 0 }}>
      {
        functions.map(f =>
          <ListItem button key={f.id} onClick={() => onSelect(f.id)}>
            <ListItemIcon>
              <SvgIcon><FontAwesomeIcon icon={faFileCode} /></SvgIcon>
            </ListItemIcon>
            <ListItemText primary={f.label} secondary={f.dialect} />
          </ListItem>
        )
      }
    </List>
  </>;
}
