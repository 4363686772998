import React, { useContext, useState } from 'react';

import { Slide, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { TransitionProps } from '@material-ui/core/transitions';

export type Message = {
    severity: 'error' | 'success';
    message: string;
}

const MessageContext = React.createContext({
    display: (message: Message) => {
        console.error('not in messages context');
    }
});

export const useMessages = () => useContext(MessageContext);

function SlideTransition(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
}

export function Messages(props: { children: React.ReactNode }) {
    const [message, setMessage] = useState<Message | undefined>(undefined);

    const context = {
        display: (message: Message) => {
            setMessage(message);
        }
    };

    return <>
        <MessageContext.Provider value={context}>
            {props.children}
        </MessageContext.Provider>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={!!message}
            autoHideDuration={6000}
            onClose={() => setMessage(undefined)}
            TransitionComponent={SlideTransition}
        >
            <Alert onClose={() => setMessage(undefined)} severity={message?.severity}>
                {message?.message}
            </Alert>
        </Snackbar>
    </>;

}