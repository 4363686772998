import React, { useRef, useLayoutEffect } from 'react';

import {
  Grid,
  TextField,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';

import { MicroControllerLayout } from './microcontroller-layout';
import { Uint8 } from '../../model/integer/uint8';
import { AssemblyCodeEditor } from './assembly-code-editor';
import { MicrocontrollerTests } from './microcontroller-tests';
import { TestCaseOneInputOneOutput } from '../../model/test/test-case';
import { componentBorderColor } from '../theme/component.setup';
import { apeArchitectures, Dialect, LoadableFunction } from '../../model/code/function';
import { generateTestCaseId } from '../../model/generateid';
import { registerWidth } from '../theme/register.setup';
import { EditorFocus } from '../../library/code-editor';
import { CodeDialectSelector } from './DialectSelector';
import { CompilableAssemblyCode } from '../../model/code/compilable-assembly-code/compilable-assembly-code';



export type MicrocontrollerCodeEditorProps = {
  run: (slow: boolean, testcase: TestCaseOneInputOneOutput) => Promise<void>;
  runAll: () => Promise<void>;
  stop: () => void;
  outputValue: Uint8[];
  running: boolean;
  inputValue: Uint8[];
  registers: Uint8[];
  ip: Uint8;
  acc: Uint8;
  highlight?: Uint8;
  loadableFunction?: LoadableFunction;
  onEdit?: (loadableFunction: LoadableFunction) => void;
  onDelete?: (loadableFunction: LoadableFunction) => void;
  statuses: Map<string, boolean | undefined>;
  errors?: number[];
  onClose?: () => void;
  editorFocus?: EditorFocus;
};
const functionEditorWidthRatio = 3.5;

export const functionEditorWidth = `${registerWidth(functionEditorWidthRatio)} + 40px`;

const useStyles = makeStyles((theme) => ({
  pointer: {
    cursor: 'move',
  },
  widthFunctionEditor: {
    width: `calc(${functionEditorWidth})`,
  },
  sectionFunctionEditor: {
    width: `calc(${functionEditorWidth})`,
    borderBottom: `1px solid ${componentBorderColor}`,
    boxShadow: `0 0 3px 1px ${componentBorderColor}`,
    padding: '3px',
  },
  heightTestPanels: {
    maxHeight: 'calc(100% - 55px - 285px - 65px)',
    overflowY: 'auto',
  },
}));

export function MicrocontrollerCodeEditor(props: MicrocontrollerCodeEditorProps) {
  const classes = useStyles();
  const loadableFunction = props.loadableFunction;

  const handleEdit = (loadableFunction: LoadableFunction) => props.onEdit?.(loadableFunction);

  const handleSelectDialect = (dialect: Dialect) => {
    if (!loadableFunction) return;
    handleEdit(loadableFunction.setDialect(dialect))
  }

  return (
    <>
      <Grid container direction="column" style={{ height: '100%' }}>
        <Grid item >
          <CodeEditorHeader
            editorFocus={props.editorFocus}
            loadableFunction={loadableFunction}
            onLabelFunctionChange={(s) => loadableFunction && handleEdit(loadableFunction.setLabel(s))}
            onDelete={props.onDelete}
          />
        </Grid>
        <Grid item >
          <CodeDialectSelector disabled={!loadableFunction} dialect={loadableFunction?.dialect} onSelect={handleSelectDialect} />
        </Grid>
        <Grid item data-tour="ide-code">
          <MicroControllerLayout
            dialect={loadableFunction?.dialect ?? Dialect.APE01}
            outputValue={props.outputValue}
            running={props.running}
            inputValue={props.inputValue}
            ip={props.highlight || props.ip}
            acc={props.acc}
            registers={props.registers}
          >
            <div style={{ width: '100%', padding: '6px' }}>
              <AssemblyCodeEditor
                highlight={props.highlight}
                highlightJmp0={props.highlight?.toNumber() === loadableFunction?.code.length()}
                code={loadableFunction?.code ?? new CompilableAssemblyCode([])}
                onChange={(code) => loadableFunction && handleEdit(loadableFunction.setCode(code))}
                disabled={!loadableFunction || props.running}
                errors={props.errors}
                editorFocus={props.editorFocus}
                runCurrentTest={() => {
                  props.runAll();
                }}
              ></AssemblyCodeEditor>
            </div>
          </MicroControllerLayout>
        </Grid>
        <Grid item className={`${classes.heightTestPanels}`} data-tour="ide-testing" sm={12}>
          {loadableFunction && <MicrocontrollerTests
            apeArchitecture={apeArchitectures[loadableFunction.dialect]}
            funcId={loadableFunction.id}
            testCases={loadableFunction.testCases.map((tc, i) => ({ testCase: tc, status: props.statuses.get(tc.id) }))}
            running={props.running}
            startTest={(testCase) => props.run(false, testCase)}
            startTestSlow={(testCase) => props.run(true, testCase)}
            startAllTests={props.runAll}
            stopTest={props.stop}
            onChange={(i, test) => handleEdit(loadableFunction.setTest(i, test))}
            addItem={() => {
              const newId = generateTestCaseId();
              const apeArchitecture = apeArchitectures[loadableFunction.dialect];
              const newTestCase = new TestCaseOneInputOneOutput(
                newId,
                `New Test`,
                [new Array(apeArchitecture.input.length).fill(new Uint8(0))],
                new Uint8(0),
                new Array(apeArchitecture.output.length).fill(new Uint8(0)));
              handleEdit(loadableFunction.addTest(newTestCase));
            }}
          />}
        </Grid>
      </Grid>
    </>
  );
}

function CodeEditorHeader({ loadableFunction, editorFocus, onLabelFunctionChange, onDelete }:
  {
    loadableFunction?: LoadableFunction;
    editorFocus?: EditorFocus;
    onLabelFunctionChange: (label: string) => void;
    onDelete?: (loadableFunction: LoadableFunction) => void;
  }
) {
  const { t } = useTranslation();
  const inputFunctionName = useRef<HTMLInputElement | null>(null);


  useLayoutEffect(() => {
    if (editorFocus === 'functionName') {
      (inputFunctionName.current as HTMLInputElement).select();
    }
  }, [editorFocus]);

  return <Grid container>
    <Grid item style={{ flexGrow: 1 }}>
      <TextField
        fullWidth
        disabled={!loadableFunction}
        label={t('test.editor.functionName')}
        value={loadableFunction?.label || ''}
        data-selector="functionName"
        onChange={(e) => onLabelFunctionChange(e.currentTarget.value)}
        autoFocus={editorFocus === 'functionName'}
        inputProps={{
          ref: inputFunctionName,
        }}
        onFocus={(e) => {
          e.currentTarget.select();
          e.stopPropagation();
        }}
      />
    </Grid>
    <Grid item>
      <IconButton disabled={!loadableFunction} onClick={() => loadableFunction && onDelete?.(loadableFunction)}>
        <DeleteIcon color={loadableFunction ? 'error' : 'disabled'} />
      </IconButton>
    </Grid>
  </Grid>;
}

