import React, { useState, useMemo, useEffect, useContext } from 'react';

import { Tabs, Tab, Grid } from '@material-ui/core';
import { useHistory, useRouteMatch, Switch, Route, useParams } from 'react-router-dom';
import { HashParams } from '../../model/types/hash-params';

type ChallengeTabs = {
  current: string;
  open: (id: string) => void;
};

export const SpawningChallengeTabsContext = React.createContext<ChallengeTabs>({
  current: '',
  open: (_: string) => {
    //
  },
});

type ChallengeTabProps = {
  id: string;
  label: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
};

export function SpawningChallengeTab(props: ChallengeTabProps) {
  return <>{props.children}</>;
}

type SpawningChallengeTabElement = React.ReactElement<ChallengeTabProps>;
type SpawningChallengeTabNode = SpawningChallengeTabElement | SpawningChallengeTabElement[];

type UrlRoutedChallengeTabsProps = {
  runTab: string;
  children: SpawningChallengeTabNode;
};
type SpawningChallengeTabsProps = {
  onTabChange?: (tab: string) => void;
  children: SpawningChallengeTabNode;
};

const ChallengeTabsContext = React.createContext({
  runChallenge: () => {
    //
  },
});

export function useChallengeTabContext() {
  return useContext(ChallengeTabsContext);
}

export function UrlRoutedChallengeTabs(props: UrlRoutedChallengeTabsProps) {
  const history = useHistory();
  const { url } = useRouteMatch();

  const openTabUrl = (tab: string, params?: HashParams) => {
    const query = params ? params.toQuery() : '';
    history.push(`${url}/${tab}${query}`);
  };

  const context = {
    runChallenge: () => {
      openTabUrl(props.runTab, new HashParams({ execute: 'true' }));
    },
  };

  return (
    <ChallengeTabsContext.Provider value={context}>
      <Switch>
        <Route exact path={[url, `${url}/:tab`]}>
          <SpawningChallengeTabs onTabChange={openTabUrl}>{props.children}</SpawningChallengeTabs>
        </Route>
      </Switch>
    </ChallengeTabsContext.Provider>
  );
}

export function SpawningChallengeTabs(props: SpawningChallengeTabsProps) {
  const { tab } = useParams<{ tab: string }>();
  const [current, setCurrent] = useState(tab || React.Children.map(props.children, (child) => child.props.id)[0]);

  const context = {
    current,
    open: (id: string) => {
      props.onTabChange && props.onTabChange(id);
    },
  };

  useEffect(() => {
    tab && setCurrent(tab);
  }, [tab]);

  const ordered = useMemo(() => {
    return React.Children.map(props.children, (child) => child);
  }, [props.children]);
  return (
    <SpawningChallengeTabsContext.Provider value={context}>
      <Tabs scrollButtons="auto" variant="scrollable" value={current} data-tour="challenge-tabs">
        {ordered.map((child) => {
          return (
            <Tab
              onClick={() => props.onTabChange && props.onTabChange(child.props.id)}
              label={
                <Grid container alignItems="stretch">
                  <Grid item>{child.props.label}</Grid>
                  {child.props.icon && (
                    <Grid item style={{ marginLeft: '12px' }}>
                      {child.props.icon}
                    </Grid>
                  )}
                </Grid>
              }
              key={child.props.id}
              value={child.props.id}
              data-tab-id={child.props.id}
            />
          );
        })}
      </Tabs>
      {React.Children.map(props.children, (child, index) => {
        if (child.props.id !== current) {
          return <div key={child.props.id}></div>;
        }
        return child.props.children;
      })}
    </SpawningChallengeTabsContext.Provider>
  );
}
