import React from 'react';

import { lightBlue, green, red } from '@material-ui/core/colors';
import { makeStyles, createMuiTheme } from '@material-ui/core';
import { registerWidth } from './register.setup';

export const fontFamily = 'Fira Code, Monospace';

export const darkColor = '#303030';
export const lightColor = '#424242';
export const contrastColor = '#858585';

export const componentBorderColor = lightBlue[200];
export const componentBorderColorDarker = lightBlue[400];
export const componentBorderColorOpacity80 = 'rgba(129, 212, 250, 0.8)';

export const useComponentStyles = makeStyles((theme) => ({
  componentBorder: (width: number) => ({
    width: `calc(${registerWidth(width)} + 10px)`,
    border: `1px solid ${componentBorderColor}`,
    padding: '3px',
    boxShadow: `0 0 3px 1px ${componentBorderColor}`,
  }),
  neonBorder: {
    border: `1px solid ${componentBorderColor}`,
    boxShadow: `0 0 3px 1px ${componentBorderColor}`,
  },
  highlightText: {
    color: componentBorderColor,
    textShadow: `0 0 5px ${componentBorderColor}`,
  },
  testSuccess: {
    color: green[500],
  },
  testFail: {
    color: red[500],
  },
  testUnknown: {
    color: theme.palette.common.white,
  },
  editorZIndex: {
    zIndex: theme.zIndex.drawer + 2,
  },
  editorZIndexPopup: {
    zIndex: theme.zIndex.drawer + 3,
  },
}));

export const useAlgopolisTheme = () =>
  React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: 'dark',
          primary: {
            main: '#FFF',
          },
        },
        overrides: {
          MuiToolbar: {
            root: {
              backgroundColor: lightColor,
            },
          },
          MuiTabs: {
            root: {
              backgroundColor: lightColor,
            },
            indicator: {
              backgroundColor: componentBorderColor,
              boxShadow: `0 0 3px 1px ${componentBorderColor}`,
            },
          },
          MuiButton: {
            containedSecondary: {
              backgroundColor: componentBorderColor,
              '&:hover': {
                backgroundColor: componentBorderColorDarker,
              },
            },
          },
          MuiTab: {
            textColorSecondary: {
              color: contrastColor,
              '&$selected': {
                color: 'white',
              },
            },
          },
          MuiBadge: {
            colorPrimary: {
              backgroundColor: componentBorderColor,
            },
          },
        },
      }),
    []
  );
