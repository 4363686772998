import React, { useContext } from 'react';

import { Grid, TextField, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Dialect } from '../../model/code/function';
import { UserContext } from '../user/user-provider';

export function CodeDialectSelector({ onSelect, dialect, disabled }: { disabled?: boolean; onSelect: (dialect: Dialect) => void; dialect?: Dialect }) {
    const { t } = useTranslation();
    const user = useContext(UserContext);


    return <Grid container >
        <Grid item xs={12}>
            <TextField
                fullWidth
                select
                disabled={disabled || !user.user.isAuthenticated}
                label={t('function.apeModel')}
                value={dialect ?? Dialect.APE01}
                onChange={(e) => { onSelect(e.target.value as Dialect) }}
            >
                {[Dialect.APE01, Dialect.APE02].map((option, idx) => <MenuItem key={option} value={option}>{option}</MenuItem>)}
            </TextField>
        </Grid>
    </Grid>;
}