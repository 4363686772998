import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline, Typography } from '@material-ui/core';
import { useAlgopolisTheme } from './components/theme/component.setup';
import { Levels } from './levels/levels';
import { ChallengesRepositoryProvider } from './components/dependencies/challenges-repository-provider';
import { Challenge } from './components/challenge/challenge';
import { UserProvider } from './components/user/user-provider';
import { Auth0Provider } from '@auth0/auth0-react';
import { useConfig } from './config';
import { PrivacyPolicy } from './privacy/Privacy';
// import { CodeLibraryScreen } from './library/code-library-screen';

function App() {
  const { loading, config } = useConfig();
  const theme = useAlgopolisTheme();

  if (loading || !config) {
    return <Typography>Loading...</Typography>;
  }
  return (
    <Auth0Provider
      domain={config.REACT_APP_AUTH0_DOMAIN}
      clientId={config.REACT_APP_AUTH0_CLIENT_ID}
      audience={config.REACT_APP_AUDIENCE}
      redirectUri={window.location.origin}
      scope="read:current_user update:current_user_metadata"
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserProvider>
          <div id="myapp" data-tour="start">
            <Router>
              <Switch>
                <Route exact path="/">
                  <ChallengesRepositoryProvider>
                    <Levels />
                  </ChallengesRepositoryProvider>
                </Route>
                {/* <Route exact path="/library">
                  <ChallengesRepositoryProvider>
                    <CodeLibraryScreen />
                  </ChallengesRepositoryProvider>
                </Route> */}
                <Route exact path="/privacy">
                  <PrivacyPolicy />
                </Route>
                <Route path="/challenge/:challengeId">
                  <ChallengesRepositoryProvider>
                    <Challenge />
                  </ChallengesRepositoryProvider>
                </Route>
              </Switch>
            </Router>
          </div>
        </UserProvider>
      </ThemeProvider>
    </Auth0Provider>
  );
}

export default App;
