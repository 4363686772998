import { RegisterType } from '../types/register-type';

/**
 * Unsigned int coded on 8 bits.
 */
export class Uint8 implements RegisterType {
  private readonly array = new Uint8Array(1);

  constructor(value: number) {
    this.array[0] = value;
  }

  toNumber(): number {
    return this.array[0];
  }
  toBinary(): string {
    const value = this.array[0];
    return `00000000${value.toString(2)}`.slice(-8);
  }
  toHex(): string {
    const value = this.array[0];
    return `00${value.toString(16)}`.slice(-2).toUpperCase();
  }

  inc(): Uint8 {
    return new Uint8(this.toNumber() + 1);
  }

  render(): string {
    return `0x${this.toHex()}`;
  }
}
