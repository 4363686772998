import { grey, green, red } from '@material-ui/core/colors';
import { RegisterState } from '../../model/microprocessor/register/register-state';

export const registerWidth = (ratio: number): string => `${ratio * 9}rem`;
export const registerHeight = (ratio: number): string => `${ratio * 2.5}rem`;

export enum RegisterDirection {
  Output = 'Output',
  Input = 'Input',
  Memory = 'Memory',
}

export type RegisterColorTuple = {
  light: string;
  dark: string;
};

export const registerColorByState: Record<RegisterState, RegisterColorTuple> = {
  [RegisterState.Inactive]: { light: grey[300], dark: grey[400] },

  [RegisterState.Active]: { light: green[700], dark: green[900] },

  [RegisterState.AllowedAction]: { light: green[300], dark: green[500] },

  [RegisterState.ForbiddenAction]: { light: red[300], dark: red[500] },
};

export function registerColor(state: RegisterState) {
  return registerColorByState[state];
}
