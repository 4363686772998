import React, { useState, useEffect } from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import { CircuitComponent, CircuitComponentType, isWire, asDirections } from '../../model/components/components';
import { rowSize, CircuitComponentDisplay, colSize } from '../circuit/circuit-component';
import { ComponentPopover, PopoverDefinition } from '../circuit/challenge-component-popover';
import { circuitComponentTypeCatalog, circuitComponentTypeCatalog2 } from '../circuit/ui/component-view';
import { isDropPossible } from '../../model/circuit/drop';
import { CircuitShadowComponent } from '../circuit/shadow-component';
import { WireDirections, WireCardinalPoint, findCardinalFrom } from '../../model/circuit/wire-directions';

type ShadowDefinition = { type: CircuitComponentType; row: number; col: number; dropPossible: boolean } | undefined;

const useStyles = makeStyles({
  blueprintGridCell: {
    width: `${colSize.size}${colSize.unit}`,
    height: `${rowSize.size}${rowSize.unit}`,
    padding: 0,
  },
  blueprintGridCellDisabled: {
    width: `${colSize.size}${colSize.unit}`,
    height: `${rowSize.size}${rowSize.unit}`,
    padding: 0,
    backgroundColor: grey[700],
    opacity: 0.4,
  },
});

export type BlueprintComponentsProps = {
  countCols?: number;
  countRows?: number;
  cells?: CircuitComponent[][];
  shadowComponentType?: CircuitComponentType;
  addComponent: (added: { row: number; col: number; type: CircuitComponentType }[]) => void;
  removeComponent: (row: number, col: number) => void;
  modifyComponent: (row: number, col: number, comp: CircuitComponent) => void;
  releaseComponentType?: () => void;
};

function replaceCell(
  cells: LocalCircuitComponent[][],
  pos: Position,
  comp: CircuitComponentType | 'wire',
  directions?: WireCardinalPoint
): LocalCircuitComponent[][] {
  const result = cells.map((row, rowi) => {
    if (rowi === pos.row) {
      return row.map((cell, colj) => {
        if (colj === pos.col) {
          let newDirections = cell.wireDirections;
          if (comp === 'wire') {
            newDirections = newDirections || new WireDirections();
            directions && newDirections.add(directions);
            const compType: CircuitComponentType = newDirections.asWire() || 'empty';
            return { id: cell.id, wire: true, componentType: compType, wireDirections: newDirections };
          }
          return { id: cell.id, wire: cell.wire, componentType: comp, wireDirections: newDirections };
        } else return cell;
      });
    } else {
      return row.concat();
    }
  });
  return result;
}

const MouseButtonLeft = 1;

type Position = {
  row: number;
  col: number;
};

function toLocaleCells(row: CircuitComponent[]): LocalCircuitComponent[] {
  return row.map((cell) => ({ ...cell, wire: isWire(cell.componentType), wireDirections: asDirections(cell.componentType) }));
}

type LocalCircuitComponent = CircuitComponent & { wire: boolean; wireDirections?: WireDirections };

export function BlueprintComponents2({
  countCols,
  countRows,
  cells,
  shadowComponentType,
  addComponent,
  removeComponent,
  modifyComponent,
}: BlueprintComponentsProps) {
  const [localCells, setLocalCells] = useState<LocalCircuitComponent[][] | undefined>(undefined);
  const [currentCell, setCurrentCell] = useState<Position | undefined>();
  const [popover, setPopover] = useState<PopoverDefinition | undefined>(undefined);
  const [shadow, setShadow] = useState<ShadowDefinition | undefined>(undefined);
  const classes = useStyles();
  const [addedComponents, setAddedComponents] = useState<Set<string>>(new Set());

  useEffect(() => {
    setShadow(shadowComponentType ? { col: -1, row: -1, dropPossible: false, type: shadowComponentType } : undefined);
  }, [shadowComponentType]);
  useEffect(() => {
    if (!cells) return;
    setLocalCells(cells.map(toLocaleCells));
  }, [cells]);

  if (!cells || !countRows || !countCols || !localCells) {
    return <></>;
  }
  return (
    <>
      {new Array(countRows).fill(1).map((_, i) => (
        <Grid key={i} container style={{ position: 'relative', top: `${countRows * rowSize.size * -1}${rowSize.unit}` }}>
          {new Array(countCols).fill(1).map((_, j) => (
            <Grid
              item
              key={i + '_' + j}
              className={`${classes.blueprintGridCell}`}
              style={{ zIndex: localCells[i][j].componentType !== 'empty' ? 10 : 1 }}
              onMouseDown={(e) => {
                const pos = { row: i, col: j };
                const cell = localCells[i][j];
                setCurrentCell(pos);
                setAddedComponents(addedComponents.add(JSON.stringify(pos)));
                if (cell.componentType === 'empty') setLocalCells(replaceCell(localCells, pos, 'wire', 'start'));
                e.preventDefault();
                e.stopPropagation();
                return false;
              }}
              onMouseUp={(e) => {
                const newCells: LocalCircuitComponent[][] = localCells.map((row) =>
                  row.map((cell) => (cell.wireDirections?.asWire() === 'startWire' ? { componentType: 'empty', wire: false } : cell))
                );
                setLocalCells(newCells);
                addComponent(
                  Array.from(addedComponents.values()).map((posStr) => {
                    const pos = JSON.parse(posStr) as Position;
                    return { row: pos.row, col: pos.col, type: newCells[pos.row][pos.col].componentType };
                  })
                );
                setCurrentCell(undefined);
                setAddedComponents(new Set());
              }}
              onMouseEnter={(e) => {
                if (e.buttons !== MouseButtonLeft) {
                  return;
                }
                const pos = { row: i, col: j };
                const current = localCells[i][j];

                const previous = currentCell;
                if (!previous) {
                  return;
                }
                const previousCell = localCells[previous.row][previous.col];
                const itemPrevious = circuitComponentTypeCatalog2(previousCell);
                if (!itemPrevious) {
                  return;
                }
                const item = circuitComponentTypeCatalog2(localCells[i][j]);
                const from = findCardinalFrom(
                  { ...previous, width: itemPrevious.width, height: itemPrevious.height },
                  { ...pos, width: item?.width || 1, height: item?.height || 1 }
                );

                let cells = localCells;
                if (previousCell.wire || previousCell.componentType === 'empty') {
                  cells = replaceCell(cells, previous, 'wire', from[0]);
                  addedComponents.add(JSON.stringify(previous));
                }
                if (current.wire || current.componentType === 'empty') {
                  cells = replaceCell(cells, pos, 'wire', from[1]);
                  addedComponents.add(JSON.stringify(pos));
                }
                setAddedComponents(addedComponents);
                setLocalCells(cells);
                setCurrentCell(pos);
              }}
              onDragOver={(e) => {
                if (shadow) {
                  setShadow({
                    ...shadow,
                    row: i,
                    col: j,
                    dropPossible: false,
                  });
                  e.preventDefault();
                }
              }}
              onDrop={(e) => {
                if (!shadow) {
                  return;
                }
                const item = circuitComponentTypeCatalog[shadow?.type];
                if (item && isDropPossible(localCells, { row: i, col: j }, item)) {
                  addComponent([{ row: i, col: j, type: shadow.type }]);
                }
              }}
            >
              <CircuitComponentDisplay
                type={localCells[i][j].componentType}
                comp={localCells[i][j]}
                onClick={(el) => {
                  const comp = localCells[i][j];
                  const item = circuitComponentTypeCatalog[comp.componentType];
                  setPopover({ row: i, col: j, actions: item?.actions, el });
                }}
                loadedFunction={localCells[i][j].functionId || '-1'}
              />
              {shadow && <Shadow row={i} col={j} shadow={shadow} cells={localCells} />}
            </Grid>
          ))}
        </Grid>
      ))}
      <ComponentPopover
        popover={popover}
        onClose={() => setPopover(undefined)}
        onDelete={removeComponent}
        selectedFunction={(popover && cells[popover?.row][popover?.col].functionId) || '-1'}
        updateSelectedFunction={(funcId) => {
          if (!popover) {
            return;
          }
          const comp = cells[popover.row][popover.col];
          const newComp = {
            ...comp,
            functionId: funcId,
          };
          modifyComponent(popover.row, popover.col, newComp);
        }}
      />
    </>
  );
}

function Shadow({ cells, col, row, shadow }: { col: number; row: number; shadow: ShadowDefinition; cells: CircuitComponent[][] }) {
  if (!(col === shadow?.col && row === shadow?.row)) {
    return <></>;
  }
  const item = circuitComponentTypeCatalog[shadow.type];
  const droppable = !item ? false : isDropPossible(cells, { col, row }, item);
  return <CircuitShadowComponent type={shadow.type} dropPossible={droppable} />;
}
