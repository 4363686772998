import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
import challengesEn from './challenges-en.json';
import DialectsEn from './dialects-en.json';

i18n.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
  lng: 'en',
  resources: {
    en: {
      translation: en,
      challenges: challengesEn,
      dialects: DialectsEn,
    },
    fr: {
      translation: {},
      challenges: {},
      dialects: {},
    },
  },
});

export default i18n;
