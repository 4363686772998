import React, { useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { Button, Typography, useTheme, AppBar, Toolbar, Grid, IconButton, Popover, MenuItem, MenuList, Hidden } from '@material-ui/core';

import { DialectDocumentationButton } from '../documentation/dialect-documentation';
import { UserIcon } from './user-icon';
import { DialogAbout } from './about';
import { UserContext } from '../user/user-provider';

export function Header() {
  const theme = useTheme();
  const history = useHistory();

  const iconSize = '3rem';

  return (
    <AppBar position="fixed" style={{ zIndex: theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Grid container style={{ alignItems: 'center' }}>
          <Grid item>
            <Button onClick={() => history.push('/')} style={{ height: '100%' }}>
              <img src="/static/icon/icon.svg" style={{ height: iconSize, width: iconSize }} alt="logo" />
              <Hidden mdDown><Typography style={{ marginLeft: theme.spacing(1) }}>Algopolis</Typography></Hidden>
            </Button>
          </Grid>
          <Grid item style={{ flexGrow: 1 }}></Grid>
          <Grid item>
            <DialectDocumentationButton />
          </Grid>
          <Grid item>
            <PersonalMenu />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export function ToolBarSpace() {
  const theme = useTheme();
  return <div style={{ minHeight: theme.mixins.toolbar.minHeight }} />;
}

function PersonalMenu() {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [openAbout, setOpenAbout] = useState(false);
  const history = useHistory();
  const currentUser = useContext(UserContext);

  return (
    <>
      <IconButton onClick={(e) => setOpen(e.currentTarget)}>
        <UserIcon />
      </IconButton>
      <Popover open={!!open} onClose={() => setOpen(null)} anchorEl={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <MenuList>
          <MenuItem disabled>{currentUser.user.isAuthenticated ? currentUser.user.email : 'Guest user'}</MenuItem>
          <MenuItem divider />
          <MenuItem onClick={() => setOpenAbout(true)}>About</MenuItem>
          <MenuItem onClick={() => history.push('/privacy')}>Privacy Policy</MenuItem>
          {!currentUser.user.isAuthenticated && <MenuItem onClick={() => currentUser.login()}>Signup / Signin</MenuItem>}
          {currentUser.user.isAuthenticated && <MenuItem onClick={() => currentUser.logout()}>Logout</MenuItem>}
        </MenuList>
      </Popover>
      <DialogAbout open={openAbout} onClose={() => setOpenAbout(false)} />
    </>
  );
}
