import React from 'react';
import { Container, Typography, Paper, useTheme, Button, Grid } from '@material-ui/core';
import { Markdown } from '../components/text/markdown';
import { useHistory } from 'react-router-dom';

export function PrivacyPolicy() {
  const theme = useTheme();
  const history = useHistory();
  return (
    <Container maxWidth="sm">
      <Typography component="h1" variant="h3" style={{ paddingTop: theme.spacing(3), paddingBottom: theme.spacing(5) }}>
        Algopolis Privacy Policy
      </Typography>
      <Paper style={{ padding: theme.spacing(2), marginBottom: theme.spacing(3) }}>
        <Markdown>
          {`
*Effective August 27, 2020*

This privacy policy will explain how our organization uses the personal data We collect from you when you use our service.

# What data do We collect?

We collect the following data:
* Email address
* First name and last name
* Profile picture or avatar
* Usage Data

# How do We collect your data?

We collect your data so that We can:

* Process your order and manage your account.
* Email you with special offers on other products and services We think you might like.
* Provide and maintain our Service by monitoring the usage of our Service.

## Partners

If you agree, We will share your data with our partner companies so that they may offer you their products and services.
* Mailchimp: https://mailchimp.com/legal/privacy/

# How do We store your data?

We will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy.
We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations 
(for example, if We are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.

The security of Your Personal Data is important to Us.

## Transfer of Your Personal Data

Your information, including Personal Data, is processed at the Service's operating offices and in any other places where the parties involved in the 
processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, 
country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.

# What are your data protection rights?

We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:

The right to **access** – You have the right to request Us for copies of your personal data.
We may charge you a small fee for this service.

The right to **rectification** – You have the right to request that We correct any information you believe is inaccurate.
You also have the right to request Us to complete the information you believe is incomplete.

The right to **erasure** – You have the right to request that We erase your personal data, under certain conditions.

The right to **restrict processing** – You have the right to request that We restrict the processing of your personal data, under certain conditions.

The right to **object to processing** – You have the right to object to Our processing of your personal data, under certain conditions.

The right to **data portability** – You have the right to request that We transfer the data that We have collected to another organization, or directly to you, under certain conditions.

If you make a request, We have one month to respond to you. If you would like to exercise any of these rights, please contact Us at our email: lioda@algopolis.io

# Cookies

## How do We use cookies?

We use cookies in a range of ways to improve your experience on our website, including:

* Keeping you signed in
* Understanding how you use our Service

## What types of cookies do We use?

There are a number of different types of cookies, however, our website uses:

*Functionality* – We use these cookies so that We recognize you on our website and remember your previously selected preferences.
These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.  
*Analytics* – We use these cookies to collect information about your visit to our website, the content you viewed,
the links you followed and information about your browser, device, and your IP address. 

## How to manage cookies

You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser.
However, in a few cases, some of our website features may not function as a result.

# Changes to our privacy policy

We keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 27 august 2020.

# Contact Us

If you have any questions about this Privacy Policy, You can contact Us by email: lioda@algopolis.io

`}
        </Markdown>
      </Paper>
      <Grid container style={{ paddingBottom: theme.spacing(5) }}>
        <Grid item style={{ flexGrow: 1 }}></Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={() => history.push('/')} style={{ margin: 'auto' }}>
            Ok, Back to the challenges !
          </Button>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}></Grid>
      </Grid>
    </Container>
  );
}
