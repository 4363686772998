import React, { useState, useEffect } from 'react';

import {
  IconButton,
  useTheme,
  TextField,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import FailIcon from '@material-ui/icons/Cancel';
import PassIcon from '@material-ui/icons/CheckCircle';
import UnknownIcon from '@material-ui/icons/Help';
import FastForwardIcon from '@material-ui/icons/FastForward';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useComponentStyles, lightColor } from '../theme/component.setup';
import { TestConfigPanel } from './test-config-panel';
import { TestCaseOneInputOneOutput } from '../../model/test/test-case';
import { useTranslation } from 'react-i18next';
import { ApeArchitecture } from '../../model/code/function';

export type MicrocontrollerTestsProps = {
  funcId: string;
  apeArchitecture: ApeArchitecture;
  testCases: { testCase: TestCaseOneInputOneOutput; status: boolean | undefined }[];
  running: boolean;
  addItem: () => void;
  onChange?: (i: number, newTestCase: TestCaseOneInputOneOutput) => void;
  startTest: (testCase: TestCaseOneInputOneOutput) => Promise<void>;
  startTestSlow: (testCase: TestCaseOneInputOneOutput) => Promise<void>;
  startAllTests: () => Promise<void>;
  stopTest: () => void;
};

export function MicrocontrollerTests(props: MicrocontrollerTestsProps) {
  const componentClasses = useComponentStyles(3.5);
  const theme = useTheme();
  const [expandeds, setExpandeds] = useState<boolean[]>(props.testCases.map((_) => false));
  const [justAdded, setJustAdded] = useState(false);
  useEffect(() => {
    setExpandeds([]);
    setJustAdded(false);
  }, [props.funcId]);

  const defaultOnChange = (i: number, newTestCase: TestCaseOneInputOneOutput) => {
    //
  };
  const onChange = props.onChange || defaultOnChange;

  return (
    <div data-tour="challenge01-functions-test" style={{ height: '100%' }}>
      <TestButtons
        running={props.running}
        canRun={!!props.testCases.length}
        addTestCase={() => {
          props.addItem();
          setExpandeds([true].concat(expandeds));
          setJustAdded(true);
        }}
        startAll={props.startAllTests}
      />
      <div style={{ flexGrow: 1 }}>
        {props.testCases.map((testCase, i) => {
          let testIcon = <UnknownIcon className={componentClasses.testUnknown} />;
          if (testCase.status) {
            testIcon = <PassIcon className={componentClasses.testSuccess} />;
          } else if (testCase.status === false) {
            testIcon = <FailIcon className={componentClasses.testFail} />;
          }
          return (
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              key={testCase.testCase.id}
              expanded={expandeds[i] || false}
              onChange={() => {
                const newExpandeds = [...expandeds];
                newExpandeds[i] = !newExpandeds[i];
                setExpandeds(newExpandeds);
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: lightColor }}>
                <Icon style={{ marginRight: theme.spacing(1) }}>{testIcon}</Icon>
                <TextField
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onFocus={(e) => {
                    e.currentTarget.select();
                    e.stopPropagation();
                  }}
                  value={testCase.testCase.name}
                  onChange={(e) => {
                    onChange(i, testCase.testCase.setName(e.currentTarget.value));
                  }}
                  size="small"
                  autoFocus={justAdded && i === 0}
                />
              </AccordionSummary>
              <AccordionDetails>
                <TabPanel
                  key={i}
                  apeArchitecture={props.apeArchitecture}
                  testCase={testCase.testCase}
                  onChange={(t) => onChange(i, t)}
                  running={props.running}
                  startTest={() => {
                    props.startTest(testCase.testCase);
                  }}
                  startTestSlow={() => {
                    props.startTestSlow(testCase.testCase);
                  }}
                  stopTest={props.stopTest}
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
}

function TestButtons({
  running,
  canRun,
  addTestCase,
  startAll,
}: {
  running: boolean;
  canRun: boolean;
  addTestCase: () => void;
  startAll: () => void;
}) {
  const [t] = useTranslation();
  const enabled = canRun && !running;
  return (
    <Grid container>
      <Grid item>
        <IconButton
          onClick={() => {
            addTestCase();
          }}
        >
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item style={{ flexGrow: 1 }}></Grid>
      <Grid item>
        <IconButton onClick={startAll} disabled={!enabled} title={t('test.button.title.runAll')}>
          <FastForwardIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

function TabPanel({
  apeArchitecture,
  testCase,
  onChange,
  ...actions
}: {
  apeArchitecture: ApeArchitecture;
  testCase: TestCaseOneInputOneOutput;
  onChange: (newTestCase: TestCaseOneInputOneOutput) => void;
  running: boolean;
  startTest: () => void;
  startTestSlow: () => void;
  stopTest: () => void;
}) {
  return (
    <TestConfigPanel
      apeArchitecture={apeArchitecture}
      testCase={testCase}
      setInValues={(arr) => onChange(testCase.setInput(arr))}
      setAccValue={(acc) => onChange(testCase.setAcc(acc))}
      setExpected={(b) => onChange(testCase.setExpected(b))}
      actions={actions}
    />
  );
}
