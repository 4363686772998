import React, { ReactNode } from 'react';

import { Header, ToolBarSpace } from '../header/header';

export function HeaderLayout(props: { children: ReactNode }) {
  return (
    <div style={{ display: 'flex' }}>
      <Header />
      <main>
        <ToolBarSpace />
        {props.children}
      </main>
    </div>
  );
}
