import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { Typography } from '@material-ui/core';
import { User } from '../../model/user/user';
import { useAuth0 } from '@auth0/auth0-react';

type UserDto = {
  tempId: string;
  isAuthenticated: boolean;
  email?: string;
  picture?: string;
};

export type CurrentUser = {
  user: User;
  login: () => void;
  logout: () => void;
};
export const UserContext = React.createContext<CurrentUser>({
  user: { tempId: '', isAuthenticated: false },
  logout: () => {
    //nothing to do
  },
  login: () => {
    //nothing to do
  },
});

export const useCurrentUser = () => React.useContext(UserContext);

export function UserProvider({ children }: { children: React.ReactNode }) {
  const { user: auth0User, isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
  const [user, setUser] = useState<User | undefined>();
  useEffect(() => {
    if (isLoading) {
      return;
    }
    async function retrieveUserId() {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        const user = await axios.get<UserDto>('/api/user', {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        setUser(user.data);
      } else {
        const user = await axios.get<UserDto>('/api/user');
        setUser(user.data);
      }
    }
    retrieveUserId();
  }, [auth0User, isLoading, isAuthenticated, getAccessTokenSilently]);

  if (!user) {
    return <Typography variant="body1">Loading...</Typography>;
  }
  return (
    <UserContext.Provider value={{ user, login: loginWithRedirect, logout: () => logout({ returnTo: window.location.origin }) }}>
      {children}
    </UserContext.Provider>
  );
}
